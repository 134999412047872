/* eslint-disable max-len */
import aurora from "../assets/sounds/notification_sound(3).mp3";
import chord from "../assets/sounds/notification_sound(2).mp3";
import bell from "../assets/sounds/notification_sound(1).mp3";
import hello from "../assets/sounds/notification_sound.mp3";
import input from "../assets/sounds/notification-sound-7062.mp3";

export const TUNES = [
  {
    audio: null,
    name: "None",
    id: 0,
  },
  {
    audio: aurora,
    name: "Aurora",
    id: 1,
  },
  {
    audio: chord,
    name: "Chord",
    id: 2,
  },
  {
    audio: bell,
    name: "Bell",
    id: 3,
  },
  {
    audio: input,
    name: "Input",
    id: 4,
  },
  {
    audio: hello,
    name: "Hello",
    id: 5,
  },
];

export const STATUS_COLOR = {
  pending: "tw-bg-[#EBFAFF] tw-text-[#2498CE]",
  success: "tw-bg-[#EBFAFF] tw-text-[#2498CE]",
  owing: "tw-bg-[#FFF0F2] tw-text-[#842432]",
  paid: "tw-bg-[#EBFAFF] tw-text-[#2498CE]",
};
export const SPECIALTIES_MAP = {
  ophthalmology: "Ophthalmology",
  generalPractice: "General Practice",
  obgyn: "Obstetrics and Gynaecology",
  endocrinology: "Endocrinology",
  cardiology: "Cardiology",
  paediatrician: "Paediatrician",
  physiotherapy: "Physiotherapy",
  orthopaedic: "Orthopaedic",
  dentist: "Dentist",
  urology: "Urology",
  neurology: "Neurology",
  oncology: "Oncology",
  nephrology: "Nephrology",
  cardiothoracic: "Cardio Thoracic",
  generalSurgeries: "General Surgeries",
  rheumatology: "Rheumatology",
  generalFamilyPhysician: "General Family Physician",
};

export const NEW_SPECIALTIES = [
  { label: "Ophthalmology", value: "ophthalmology" },
  { label: "General Practice", value: "generalPractice" },
  { label: "Obstetrics and Gynaecology", value: "obgyn" },
  { label: "Endocrinology", value: "endocrinology" },
  { label: "Cardiology", value: "cardiology" },
  { label: "Paediatrician", value: "paediatrician" },
  { label: "Physiotherapy", value: "physiotherapy" },
  { label: "Orthopaedic", value: "orthopaedic" },
  { label: "Dentist", value: "dentist" },
  { label: "Urology", value: "urology" },
  { label: "Neurology", value: "neurology" },
  { label: "Oncology", value: "oncology" },
  { label: "Nephrology", value: "nephrology" },
  { label: "Cardio Thoracic", value: "cardiothoracic" },
  { label: "General Surgeries", value: "generalSurgeries" },
  { label: "Rheumatology", value: "rheumatology" },
  { label: "General Family Physician", value: "generalFamilyPhysician" },
];
export const SECTIONS = ["Vitals", "Consultant", "Outpatient Procedure", "Outpatient Surgery"];

export const POINT_OF_SALE = [
  { label: "Retail Item", value: "retail" },
  { label: "Prescription Item", value: "prescription" },
  { label: "Non Medical Item", value: "service" },
];
export const PERSONAL_DETAILS = [
  {
    _id: 1,
    checked: false,
    text: "Location",
    value: "address",
  },
  { _id: 2, checked: false, text: "Email Address", value: "email" },
];
export const NEXT_OF_KIN = [
  { _id: 7, checked: false, text: "Full Name", value: "nokFullName" },
  { _id: 8, checked: false, text: "Relationship", value: "nokRelationship" },
  { _id: 9, checked: false, text: "Phone Number", value: "nokPhoneNumber" },
  { _id: 10, checked: false, text: "Email Address", value: "nokEmail" },
  {
    _id: 11,
    checked: false,
    text: "Location",
    value: "nokAddress",
  },
];
export const HMO_DETAILS = [{ _id: 12, checked: false, text: "HMO/Provider", value: "sponsors" }];
export const EXTRA_DETAILS = [
  { _id: 13, checked: false, text: "Referral", value: "referral" },
  { _id: 14, checked: false, text: "Allergies", value: "allergies" },
  { _id: 15, checked: false, text: "Patient ID Number", value: "companyID" },
  { _id: 16, checked: false, text: "Department", value: "companyDepartment" },
];

export const DAYS_OF_THE_WEEK = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const APPOINTMENTS_SLOTS = Array.from({ length: 60 }, (_, id) => id + 1).map((slotValue) => ({
  label: `${slotValue} minute${slotValue === 1 ? "" : "s"}`,
  value: `${slotValue}`,
}));

export const UNIT_OF_PURCHASE_OPTIONS = [
  { label: "Bottle", value: "Bottle" },
  { label: "Box", value: "Box" },
  { label: "Cartons", value: "Cartons" },
  { label: "Case", value: "Case" },
  { label: "Cups", value: "Cups" },
  { label: "Dozen", value: "Dozen" },
  { label: "Each", value: "Each" },
  { label: "Gallon", value: "Gallon" },
  { label: "Inches", value: "Inches" },
  { label: "Oz", value: "Oz" },
  { label: "Packs", value: "Packs" },
  { label: "Sheet", value: "Sheet" },
  { label: "Yard", value: "Yard" },
];

export const PRODUCT_VARIANT_OPTIONS = [
  { label: "Colour", value: "colour" },
  { label: "Size", value: "size" },
  { label: "Length", value: "length" },
  { label: "Gender", value: "gender" },
  { label: "Class", value: "class" },
  { label: "Adult or Kid", value: "adult or kid" },
  { label: "Type", value: "type" },
  { label: "Frame", value: "frame" },
  { label: "Material", value: "material" },
  { label: "Style", value: "style" },
  { label: "Bridge", value: "bridge" },
  { label: "Model", value: "model" },
];

export const UNIT_OF_SALES_OPTIONS = [
  { label: "Ampoules(s)", value: "Ampoules(s)" },
  { label: "Bottle(s)", value: "Bottle(s)" },
  { label: "Bag(s)", value: "Bag(s)" },
  { label: "Can(s)", value: "Can(s)" },
  { label: "Catheter", value: "Catheter" },
  { label: "Capsule(s)", value: "Capsule(s)" },
  { label: "ML(Ampoules)", value: "ML(Ampoules)" },
  { label: "ML(syrub)", value: "ML(syrub)" },
  { label: "Pessary(Capsules)", value: "Pessary(Capsules)" },
  { label: "Roll(s)", value: "Roll(s)" },
  { label: "Strip", value: "Strip" },
  { label: "Supp(Capsules)", value: "Supp(Capsules)" },
  { label: "Tablet(s)", value: "Tablet(s)" },
  { label: "Tube(s)", value: "Tube(s)" },
  { label: "Vial(s)", value: "Vial(s)" },
];

export const PATIENT_FILTER_OPTIONS = ["Name", "MRN", "Phone Number", "Email", "Department", "ID NO"];

export const DOSE_FREQUENCY_OPTIONS = [
  { label: "once a day (OD)", value: "once a day (OD)" },
  { label: "twice a day (BID)", value: "twice a day (BID)" },
  { label: "three times a day (TID)", value: "three times a day (TID)" },
  { label: "four times a day (QID)", value: "four times a day (QID)" },
  { label: "five times a day", value: "five times a day" },
  { label: "Immediately (STAT)", value: "Immediately (STAT)" },
  { label: "Every four hours (Q4H)", value: "Every four hours (Q4H)" },
  { label: "Every five hours (Q5H)", value: "Every five hours (Q5H)" },
  { label: "every six hours (Q6H)", value: "every six hours (Q6H)" },
  { label: "bedtime", value: "bedtime" },
  { label: "nocte", value: "nocte" },
  { label: "30 minutes", value: "30 minutes" },
  { label: "1 hourly", value: "1 hourly" },
  { label: "2 hourly", value: "2 hourly" },
  { label: "4 hourly", value: "4 hourly" },
  { label: "8 hourly", value: "8 hourly" },
  { label: "10 hourly", value: "10 hourly" },
  { label: "12 hourly", value: "12 hourly" },
];

export const DOSAGE_ROUTE_OPTIONS = [
  { label: "Mouth/oral", value: "Mouth/oral" },
  { label: "Intermuscular/Injection", value: "Intermuscular/Injection" },
  { label: "Intravenous/Injection", value: "Intravenous/Injection" },
  { label: "Intravenous Push", value: "Intravenous Push" },
  { label: "Subcutaneous", value: "Subcutaneous" },
  { label: "Rectal", value: "Rectal" },
  { label: "Vagina", value: "Vagina" },
  { label: "Nasal", value: "Nasal" },
  { label: "Intradermal", value: "Intradermal" },
  { label: "Total Parenteral nutrition", value: "Total Parenteral nutrition" },
  { label: "Nothing by mouth", value: "Nothing by mouth" },
  { label: "Sublingual", value: "Sublingual" },
  { label: "Ocular", value: "Ocular" },
  { label: "Otic", value: "Otic" },
  { label: "Inhalation", value: "Inhalation" },
  { label: "Nubulization", value: "Nubulization" },
  { label: "Cutaneous", value: "Cutaneous" },
  { label: "Transdermal", value: "Transdermal" },
];

export const DOSAGE_FORM_OPTIONS = [
  { label: "Tablets", value: "Tablets" },
  { label: "Capsules", value: "Capsules" },
  { label: "mg", value: "mg" },
  { label: "mL", value: "mL" },
  { label: "Tsp", value: "Tsp" },
  { label: "Tbsp", value: "Tbsp" },
  { label: "oz", value: "oz" },
  { label: "Microgram", value: "microgram" },
  { label: "Cup", value: "Cup" },
  { label: "Drops", value: "Drops" },
  { label: "Syrup", value: "Syrup" },
  { label: "Powder", value: "Powder" },
  { label: "Injections", value: "Injections" },
];

export const ROLES = [
  { value: "admin", label: "admin" },
  { value: "staff", label: "staff" },
];

export const BRANCHES = [
  { value: "ikeja", label: "ikeja" },
  { value: "lekki", label: "lekki" },
];

export const DEPARTMENTS = [
  { value: "optical", label: "optical" },
  { value: "clinical", label: "clinical" },
];

export const REFERRAL_OPTIONS = [
  { label: "Facebook", value: "Facebook" },
  { label: "LinkedIn", value: "LinkedIn" },
  { label: "Twitter", value: "Twitter" },
  { label: "Website", value: "Website" },
  { label: "Friend", value: "Friend" },
  { label: "Poster", value: "Poster" },
  { label: "Hospital Outreach Programme", value: "Hospital Outreach Programme" },
  { label: "Others", value: "Others" },
];

export const TODO = "pending";
export const PENDING = "accepted";
export const IN_PROGRESS = "active";
export const COMPLETED = "completed";
export const DISPATCHED = "delivered";
export const DECLINED = "declined";

export const ORDER_STATE_OPTIONS = [
  { label: "All", value: "" },
  { label: "Todo", value: TODO },
  { label: "Pending", value: PENDING },
  { label: "Active", value: IN_PROGRESS },
  { label: "Completed", value: COMPLETED },
  { label: "Dispatched", value: DISPATCHED },
  { label: "Declined", value: DECLINED },
];

export const RELATIONSHIP_OPTIONS = [
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Sibling", value: "Sibling" },
  { label: "Cousin", value: "Cousin" },
  { label: "Relation", value: "Relation" },
  { label: "Spouse", value: "Spouse" },
  { label: "Friend", value: "Friend" },
];

export const ICD_CODES = [
  { label: "ICD 9", value: "icd9" },
  { label: "ICD 10", value: "icd10" },
];

export const TIME_ZONES = [
  { label: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
  { label: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
  { label: "(GMT -10:00) Hawaii", value: "-10:00" },
  { label: "(GMT -9:30) Taiohae", value: "-09:50" },
  { label: "(GMT -9:00) Alaska", value: "-09:00" },
  { label: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00" },
  { label: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00" },
  {
    label: "(GMT -6:00) Central Time (US & Canada), Mexico City",
    value: "-06:00",
  },
  {
    label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
    value: "-05:00",
  },
  { label: "(GMT -4:30) Caracas", value: "-04:50" },
  {
    label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
    value: "-04:00",
  },
  { label: "(GMT -3:30) Newfoundland", value: "-03:50" },
  { label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
  { label: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
  { label: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
  {
    label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
    value: "+00:00",
  },
  { label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
  { label: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
  {
    label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
    value: "+03:00",
  },
  { label: "(GMT +3:30) Tehran", value: "+03:50" },
  { label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
  { label: "(GMT +4:30) Kabul", value: "+04:50" },
  {
    label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    value: "+05:00",
  },
  { label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50" },
  { label: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
  { label: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
  { label: "(GMT +6:30) Yangon, Mandalay", value: "+06:50" },
  { label: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
  {
    label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
    value: "+08:00",
  },
  { label: "(GMT +8:45) Eucla", value: "+08:75" },
  {
    label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    value: "+09:00",
  },
  { label: "(GMT +9:30) Adelaide, Darwin", value: "+09:50" },
  {
    label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
    value: "+10:00",
  },
  { label: "(GMT +10:30) Lord Howe Island", value: "+10:50" },
  {
    label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
    value: "+11:00",
  },
  { label: "(GMT +11:30) Norfolk Island", value: "+11:50" },
  {
    label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    value: "+12:00",
  },
  { label: "(GMT +12:45) Chatham Islands", value: "+12:75" },
  { label: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
  { label: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" },
];

export const TIMEZONES_UTC = [
  { label: "(UTC -12:00) International Date Line West", value: "-12:00" },
  { label: "(UTC -11:00) Coordinated Universal Time-11", value: "-11:00" },
  { label: "(UTC -10:00) Hawaii", value: "-10:00" },
  { label: "(UTC -09:00) Alaska", value: "-09:00" },
  { label: "(UTC -08:00) Baja California, Pacific Time (US and Canada)", value: "-08:00" },
  { label: "(UTC -07:00) Chihuahua, La Paz, Mazatlan, Arizona, Mountain Time (US and Canada)", value: "-07:00" },
  {
    label:
      "(UTC -06:00) Central America, Central Time (US and Canada), Saskatchewan, Guadalajara, Mexico City, Monterey",
    value: "-06:00",
  },
  { label: "(UTC -05:00) Bogota, Lima, Quito, Indiana (East), Eastern Time (US and Canada)", value: "-05:00" },
  { label: "(UTC -04:30) Caracas", value: "-04:30" },
  {
    label: "(UTC -04:00) Atlantic Time (Canada), Asuncion, Georgetown, La Paz, Manaus, San Juan, Cuiaba, Santiago",
    value: "-04:00",
  },
  { label: "(UTC -03:30) Newfoundland", value: "-03:30" },
  { label: "(UTC -03:00) Brasilia, Greenland, Cayenne, Fortaleza, Buenos Aires, Montevideo", value: "-03:00" },
  { label: "(UTC -02:00) Coordinated Universal Time-2", value: "-02:00" },
  { label: "(UTC -01:00) Cape Verde, Azores", value: "-01:00" },
  { label: "(UTC +00:00) Casablanca, Monrovia, Reykjavik, Dublin, Edinburgh, Lisbon, London", value: "+00:00" },
  {
    label:
      "(UTC +01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Brussels, Copenhagen, Madrid, Paris, West Central Africa, Belgrade, Bratislava, Budapest, Ljubljana, Prague, Sarajevo, Skopje, Warsaw, Zagreb, Windhoek",
    value: "+01:00",
  },
  {
    label:
      "(UTC +02:00) Athens, Bucharest, Istanbul, Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius, Cairo, Damascus, Amman, Harare, Pretoria, Jerusalem, Beirut",
    value: "+02:00",
  },
  {
    label: "(UTC +03:00) Baghdad, Minsk, Kuwait, Riyadh, Nairobi",
    value: "+03:00",
  },
  { label: "(UTC +03:30) Tehran", value: "+03:30" },
  {
    label: "(UTC +04:00) Moscow, St. Petersburg, Volgograd, Tbilisi, Yerevan, Abu Dhabi, Muscat, Baku, Port Louis",
    value: "+04:00",
  },
  { label: "(UTC +04:30) Kabul", value: "+04:30" },
  { label: "(UTC +05:00) Tashkent, Islamabad, Karachi", value: "+05:00" },
  { label: "(UTC +05:30) Sri Jayewardenepura Kotte, Chennai, Kolkata, Mumbai, New Delhi", value: "+05:30" },
  { label: "(UTC +05:45) Kathmandu", value: "+05:45" },
  { label: "(UTC +06:00) Astana, Dhaka, Yekaterinburg", value: "+06:00" },
  { label: "(UTC +06:30) Yangon", value: "+06:30" },
  { label: "(UTC +06:30) Yangon", value: "+06:30" },
  { label: "(UTC +07:00) Bangkok, Hanoi, Jakarta, Novosibirsk", value: "+07:00" },
  {
    label:
      "(UTC +08:00) Krasnoyarsk, Ulaanbaatar, Beijing, Chongqing, Hong Kong, Urumqi, Perth, Kuala Lumpur, Singapore, Taipei",
    value: "+08:00",
  },
  { label: "(UTC +09:00) Irkutsk, Seoul, Osaka, Sapporo, Tokyo", value: "+09:00" },
  { label: "(UTC +09:30) Darwin, Adelaide", value: "+09:30" },
  { label: "(UTC +10:00) Hobart, Yakutsk, Brisbane, Guam, Port Moresby, Canberra, Melbourne, Sydney", value: "+10:00" },
  { label: "(UTC +11:00) Vladivostok, Solomon Islands, New Caledonia", value: "+11:00" },
  {
    label: "(UTC +12:00) Coordinated Universal Time+12, Fiji, Marshall Islands, Magadan, Auckland, Wellington",
    value: "+12:00",
  },
  { label: "(UTC +13:00) Nuku'alofa, Samoa", value: "+13:00" },
];

export const REQUIRED_COMPLAINT_KEYS = [
  "rightEye",
  "leftEye",
  "historyOfPresentEye",
  "previousEyeProblems",
  "summary",
  "haveYouSortAdviceBefore",
  "otherComments",
];

export const REQUIRED_OP_CASNOTE_KEYS = [
  "complaint",
  "anteriorSegmentExam",
  "extraOcularMuscleMovement",
  "fundus",
  "pastOcularHistory",
  "posteriorSegmentExam",
  "refraction",
  "visualAcuity",
  "selectedEye",
  "leftEyeOtherDiagnosis",
  "rightEyeOtherDiagnosis",
  "leftEyeDiagnosis",
  "rightEyeDiagnosis",
  "followUp",
  "narrative",
  "attachments",
  "signedBy",
  "linkedVisit",
  "recommendations",
];

export const REQUIRED_GENERAL_CASNOTE_KEYS = [
  "complaint",
  "narrative",
  "attachments",
  "signedBy",
  "linkedVisit",
  "recommendations",
  "systemReview",
  "medicalHistory",
  "physicalExamination",
  "diagnosis",
  "otherDiagnosis",
];
export const SWIPER_PARAMS = {
  containerclass: "swiper-container p-1 pb-3 pr-4",
  slidesPerView: 1,
  spaceBetween: 20,
  scrollbar: {
    el: ".swiper-scrollbar",
  },
  breakpoints: {
    1600: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1330: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },

    1200: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
    830: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    650: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    450: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
};

export const EXTRA_OCULAR_OPTIONS = [
  { label: "IOP", value: "iop" },
  { label: "IOP Adjusted", value: "adjusted" },
  { label: "Pupils", value: "pupils" },
  { label: "Orbit", value: "orbit" },
  { label: "Lids", value: "lids" },
  { label: "Conjunctiva", value: "conjunctiva" },
  { label: "Cornea", value: "cornea" },
  { label: "AC / Iris", value: "acIris" },
  { label: "Lens", value: "lens" },
  { label: "Vitreous", value: "vitreous" },
];

export const FUNDUS_OPTIONS = [
  { label: "Disc", value: "disk" },
  { label: "Vessels", value: "vessels" },
  { label: "Macular", value: "macular" },
  { label: "Periphery", value: "periphery" },
];

export const REFRACTION_OPTIONS = [
  { label: "Retinoscopy", value: "retinoscopy" },
  { label: "Subjective", value: "subjective" },
];
export const VISUAL_ACUITY_OPTIONS = [
  { label: "CC", value: "cc" },
  { label: "PH", value: "ph" },
  { label: "NEAR", value: "near" },
  { label: "SC", value: "sc" },
];

export const FILTER_OPTION = [
  { label: "Name", value: "name" },
  { label: "MRN", value: "mrn" },
  { label: "Phone Number", value: "phoneNumber" },
  { label: "Email", value: "email" },
  { label: "Department", value: "companyDepartment" },
  { label: "ID NO", value: "staffId" },
];

export const PATIENT_RELIGION = [
  { label: "Atheism", value: "Atheism" },
  { label: "Baha'i", value: "Baha'i" },
  { label: "Buddhism", value: "Buddhism" },
  { label: "Candomblé", value: "Candomblé" },
  { label: "Christianity", value: "Christianity" },
  { label: "Hinduism", value: "Hinduism" },
  { label: "Islam", value: "Islam" },
  { label: "Jainism", value: "Jainism" },
  { label: "Jehovah's Witnesses", value: "Jehovah's Witnesses" },
  { label: "Mormonism", value: "Mormonism" },
  { label: "Paganism", value: "Paganism" },
  { label: "Rastafari", value: "Rastafari" },
  { label: "Santeria", value: "Santeria" },
  { label: "Shintoism", value: "Shintoism" },
  { label: "Sikhism", value: "Sikhism" },
  { label: "Spiritualism", value: "Spiritualism" },
  { label: "Taoism", value: "Taoism" },
  { label: "Unitarianism", value: "Unitarianism" },
  { label: "Zoroastrianism", value: "Zoroastrianism" },
  { label: "Traditional beliefs", value: "Traditional beliefs" },
  { label: "Syncretic", value: "Syncretic" },
  { label: "Non-religious", value: "Non-religious" },
];

export const DESCRIBE_COMPLAINT_OPTIONS = [
  { label: "Acute", value: "Acute" },
  { label: "Chronic", value: "Chronic" },
  { label: "Subacute", value: "Subacute" },
  { label: "Worse at night", value: "Worse at night" },
  { label: "Worse during the day", value: "Worse during the day" },
  { label: "Constant", value: "Constant" },
  { label: "Intermittent ", value: "Intermittent " },
  { label: "Colicky", value: "Colicky" },
  { label: "Sharp", value: "Sharp" },
  { label: "Crushing", value: "Crushing" },
  { label: "Pressured", value: "Pressured" },
  { label: "Worse with food", value: "Worse with food" },
  { label: "Worse without food", value: "Worse without food" },
  { label: "Worse with exertion", value: "Worse with exertion" },
  { label: "Relieved at rest", value: "Relieved at rest" },
  { label: "Projectile", value: "Projectile" },
  { label: "Non Projectile", value: "Non Projectile" },
];

export const SEVERITY_COLOR = [
  { label: "None", value: "None" },
  { label: "Minor", value: "Minor" },
  { label: "Moderate", value: "Moderate" },
  { label: "Major", value: "Major" },
  { label: "Extreme", value: "Extreme" },
];

export const FORM_IDS = {
  saveVitalsForm: "saveVitalsForm",
  addCaseNoteForm: "addCaseNoteForm",
  saveComplaintForm: "saveComplaintForm",
  presentingComplaintCaseNoteForm: "presentingComplaintCaseNoteForm",
  reviewOfSystemCaseNoteForm: "reviewOfSystemCaseNoteForm",
  medicalHistoryCaseNoteForm: "medicalHistoryCaseNoteForm",
  physicalExaminationCaseNoteForm: "physicalExaminationCaseNoteForm",
  otherDiagnosisCaseNoteForm: "otherDiagnosisCaseNoteForm",
  requestLabInvestigationCaseNoteForm: "requestLabInvestigationCaseNoteForm",
  primaryDiagnosisCaseNoteForm: "physicalExaminationCaseNoteForm",
  addPrescriptionCaseNoteForm: "addPrescriptionCaseNoteForm",
  addPrescriptionCaseNoteForm2: "addPrescriptionCaseNoteForm2",
  referralForm: "referralForm",
  SaveBookSurgery: "SaveBookSurgery",
  SaveProcedure: "SaveProcedure",
  SaveProcedureInOffice: "SaveProcedure",
  SaveProcedureInternal: "SaveProcedureInternal",
  SaveProcedureExternal: "SaveProcedureExternal",
  saveAdmitPatient: "saveAdmitPatient",
  saveAppointment: "saveAppointment",
  savePrescription: "savePrescription",
  saveFollowUp: "saveFollowUp",
  addProgressNoteForm: "addProgressNoteForm",
  addPastRecordForm: "addPastRecordForm",
  addConsumablesForm: "addConsumablesForm",
  addAdmissionServices: "addAdmissionServices",
  addNursingNote: "addNursingNote",
  saveVisualAcuity: "saveVisualAcuity",
  saveReviewOfSystems: "saveReviewOfSystems",
  saveAnteriorSegmentExamination: "saveAnteriorSegmentExamination",
  savePosteriorSegmentExamination: "savePosteriorSegmentExamination",
  saveFundus: "saveFundus",
  saveExtraOcularMuscleMovement: "saveExtraOcularMuscleMovement",
  savePastOcularHistory: "savePastOcularHistory",
  counselorsForm: "counselorsForm",
  patientSurgeryChecklist: "patientSurgeryChecklist",
  operationRoomChecklist: "operationRoomChecklist",
  preOperativeFormIntraOperative: "preOperativeFormIntraOperative",
  anesthesiaRecords: "anesthesiaRecords",
  generalOperationForm: "generalOperationForm",
  postOperativeForm: "postOperativeForm",
};

export const SURGERY_LOCATIONS = [
  { label: "Theatre", value: "theatre" },
  { label: "In Office", value: "inOffice" },
];

export const CUSTOM_ERROR_MESSAGE = "Oops something went wrong";

export const SOCKET_EVENTS = [
  {
    title: "New Appointment",
    event: "newAppointment",
  },
  {
    title: "Vitals Done",
    event: "newConsultingAppointment",
  },
  {
    title: "Internal Lab Investigation",
    event: "newInternalLabInvestigation",
  },
  {
    title: "External Lab Investigation",
    event: "newExternalLabInvestigation",
  },
  {
    title: "Private Patient Admitted",
    event: "newAdmission",
  },
  {
    title: "Test Result",
    event: "testTypeResult",
  },
  {
    title: "Consultant Transfer",
    event: "consultantTransfer",
  },
  {
    title: "Release Notes",
    event: "release-notes",
  },
];

export const EDITOR_TOOLBAR = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough", "monospace", "superscript", "subscript"],
    // bold: { icon: bold, className: undefined },
    // italic: { icon: italic, className: undefined },
    // underline: { icon: underline, className: undefined },
    // strikethrough: { icon: strikethrough, className: undefined },
    // monospace: { icon: monospace, className: undefined },
    // superscript: { icon: superscript, className: undefined },
    // subscript: { icon: subscript, className: undefined },
  },
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    icon: undefined,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered", "indent", "outdent"],
    // unordered: { icon: unordered, className: undefined },
    // ordered: { icon: ordered, className: undefined },
    // indent: { icon: indent, className: undefined },
    // outdent: { icon: outdent, className: undefined },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
    // left: { icon: left, className: undefined },
    // center: { icon: center, className: undefined },
    // right: { icon: right, className: undefined },
    // justify: { icon: justify, className: undefined },
  },
  colorPicker: {
    icon: undefined,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)",
    ],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link", "unlink"],
    // link: { icon: link, className: undefined },
    // unlink: { icon: unlink, className: undefined },
    linkCallback: undefined,
  },
  emoji: {
    // icon: emoji,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      "😀",
      "😁",
      "😂",
      "😃",
      "😉",
      "😋",
      "😎",
      "😍",
      "😗",
      "🤗",
      "🤔",
      "😣",
      "😫",
      "😴",
      "😌",
      "🤓",
      "😛",
      "😜",
      "😠",
      "😇",
      "😷",
      "😈",
      "👻",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "🙈",
      "🙉",
      "🙊",
      "👼",
      "👮",
      "🕵",
      "💂",
      "👳",
      "🎅",
      "👸",
      "👰",
      "👲",
      "🙍",
      "🙇",
      "🚶",
      "🏃",
      "💃",
      "⛷",
      "🏂",
      "🏌",
      "🏄",
      "🚣",
      "🏊",
      "⛹",
      "🏋",
      "🚴",
      "👫",
      "💪",
      "👈",
      "👉",
      "👉",
      "👆",
      "🖕",
      "👇",
      "🖖",
      "🤘",
      "🖐",
      "👌",
      "👍",
      "👎",
      "✊",
      "👊",
      "👏",
      "🙌",
      "🙏",
      "🐵",
      "🐶",
      "🐇",
      "🐥",
      "🐸",
      "🐌",
      "🐛",
      "🐜",
      "🐝",
      "🍉",
      "🍄",
      "🍔",
      "🍤",
      "🍨",
      "🍪",
      "🎂",
      "🍰",
      "🍾",
      "🍷",
      "🍸",
      "🍺",
      "🌍",
      "🚑",
      "⏰",
      "🌙",
      "🌝",
      "🌞",
      "⭐",
      "🌟",
      "🌠",
      "🌨",
      "🌩",
      "⛄",
      "🔥",
      "🎄",
      "🎈",
      "🎉",
      "🎊",
      "🎁",
      "🎗",
      "🏀",
      "🏈",
      "🎲",
      "🔇",
      "🔈",
      "📣",
      "🔔",
      "🎵",
      "🎷",
      "💰",
      "🖊",
      "📅",
      "✅",
      "❎",
      "💯",
    ],
  },
  embedded: {
    // icon: embedded,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
  image: {
    // icon: image,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
  remove: {
    // icon: eraser,
    className: undefined,
    component: undefined,
  },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["undo", "redo"],
    // undo: { icon: undo, className: undefined },
    // redo: { icon: redo, className: undefined },
  },
};

export const MEDICAL_RECORD_MODELS = {
  WardRound: "WardRound",
  NursingNote: "NursingNote",
  RxPrescription: "RxPrescription",
  Procedure: "Procedure",
  ProgressNote: "ProgressNote",
  CaseNotes: "CaseNotes",
  MedicalReferral: "MedicalReferral",
  Vital: "Vital",
  Investigation: "Investigation",
  Assessment: "Assessment",
  ContinuousAssessment: "ContinuousAssessment",
  SoapNote: "SoapNote",
  Observation: "Observation",
  FluidBalance: "FluidBalance",
  IVFluid: "IVFluid",
  ConsentForm: "ConsentForm",
  Surgery: "Surgery",
  FormEntry: "FormEntry",
};

export const MEDICAL_RECORD_MODELS_OPTIONS = [
  { label: "All", value: "" },
  { label: "WardRound", value: "WardRound" },
  { label: "NursingNote", value: "NursingNote" },
  { label: "RxPrescription", value: "RxPrescription" },
  { label: "Procedure", value: "Procedure" },
  { label: "ProgressNote", value: "ProgressNote" },
  { label: "CaseNotes", value: "CaseNotes" },
  { label: "MedicalReferral", value: "MedicalReferral" },
  { label: "Vital", value: "Vital" },
  { label: "Investigation", value: "Investigation" },
  { label: "Assessment", value: "Assessment" },
  { label: "ContinuousAssessment", value: "ContinuousAssessment" },
  { label: "SoapNote", value: "SoapNote" },
  { label: "Observation", value: "Observation" },
  { label: "FluidBalance", value: "FluidBalance" },
  { label: "IVFluid", value: "IVFluid" },
  { label: "ConsentForm", value: "ConsentForm" },
  { label: "Surgery", value: "Surgery" },
  { label: "FormEntry", value: "FormEntry" },
];
