import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess } from "utils";
import { closeAllModals } from "./modalSlice";

export const staffApi = createApi({
  reducerPath: "staffApi",
  baseQuery,
  tagTypes: ["Staff"],
  endpoints: (builder) => ({
    getStaff: builder.query({
      query: (queryParams) => ({
        url: `/staff`,
        params: queryParams
      }),
      providesTags: ["Staff"],
      transformResponse: (response) => response.data,
    }),
    // deleteCategory: builder.mutation({
    //   query: (id) => ({
    //     url: `/categories/${id}`,
    //     method: "DELETE",
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const response = await queryFulfilled;
    //       _notifySuccess(`Success : ${response.data.message}`);
    //       dispatch(closeAllModals());
    //     } catch (err) {
    //       _notifyError(`Error : ${err.error.data.message}`);
    //     }
    //   },
    //   invalidatesTags: ["Staff"],
    // }),
    addStaff: builder.mutation({
      query: (current) => ({
        url: `/staff`,
        method: "POST",
        body: current,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Staff"],
    }),
    updateStaff: builder.mutation({
      query: ({ current }) => {
        const { email, name, phoneNumber, locations, sections, departments, specialties, isActive, _id } = current;
        const payload = { email, name, phoneNumber, locations, sections, departments, specialties, isActive };
        return {
          url: `/staff/${_id}`,
          method: "PATCH",
          body: payload,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Staff"],
    }),
  }),
});

export const { useGetStaffQuery, useAddStaffMutation, useUpdateStaffMutation, useStaffCategoryMutation } =
  staffApi;
