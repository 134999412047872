import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, printError } from "utils";
import { closeAllModals } from "store/features/modalSlice";

export const claimsApi = createApi({
  reducerPath: "claimsApi",
  baseQuery,
  endpoints: (builder) => ({
    getClaims: builder.query({
      query: (queryParams) => ({
        url: `/claims`,
        params: queryParams,
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Claim_Stats"],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    getClaimsDashboardStats: builder.query({
      query: (branchId) => `/branches/${branchId}/claims/dashboard-stats`,
      transformResponse: (response) => response.data,
      providesTags: ["Claim_Stats"],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    markClaimAsSubmitted: builder.mutation({
      query: (ids) => ({
        url: `/claims/submissions`,
        method: "POST",
        body: ids,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Claims", "Claim_Stats"],
    }),
    markClaimAsPaid: builder.mutation({
      query: (ids) => ({
        url: `/claims/payments`,
        method: "POST",
        body: ids,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());

          //
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Claims", "Claim_Stats"],
    }),
  }),
});

export const {
  useGetClaimsQuery,
  useGetClaimsDashboardStatsQuery,
  useMarkClaimAsSubmittedMutation,
  useMarkClaimAsPaidMutation,
} = claimsApi;
