export const SMS = [
    {
        title: "Registration",
        isSystemMessage: true,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Patient Appointment Remin...",
        isSystemMessage: true,
        status: 'inactive',
        date: '20-10-2020'
    },
    {
        title: "Invoice",
        isSystemMessage: true,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Referral",
        isSystemMessage: true,
        status: 'inactive',
        date: '20-10-2020'
    },
    {
        title: "Investigate",
        isSystemMessage: true,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Procedure",
        isSystemMessage: false,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Birthday Message",
        isSystemMessage: false,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Appreciation Message",
        isSystemMessage: false,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Wallet Deduction",
        isSystemMessage: false,
        status: 'inactive',
        date: '20-10-2020'
    },
    {
        title: "End Consultation",
        isSystemMessage: false,
        status: 'active',
        date: '20-10-2020'
    },
    {
        title: "Christmas Message",
        isSystemMessage: false,
        status: 'active',
        date: '20-10-2020'
    },
]

export const SMS_LOGS = [
    {
        recipient: {
            name: "Layor Pan",
            phoneNumber: "+234708108023"
        },
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        status: 'sent',
        createdAt: '2024-09-05T10:11:19.985+00:00',
        amount: 4000
    },
    {
        title: "Patient Appointment Remin...",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        status: 'sent',
        createdAt: '2024-09-05T10:11:19.985+00:00',
        recipient: {
            name: "Layor Pan",
            phoneNumber: "+234708108023"
        },
        amount: 40000
    },
    {
        title: "Invoice",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: true,
        status: 'delivered',
        createdAt: '2021-06-01T00:00:00.000+00:00',
        recipient: {
            name: "Albert Flores",
            phoneNumber: "09058903456"
        },
        amount: 400
    },
    {
        title: "Referral",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: true,
        status: 'failed',
        createdAt: '2021-06-11T10:52:21.719+00:00',
        recipient: {
            name: "Guy Hawkins",
            phoneNumber: "(09036759987)"
        },
        amount: 4000
    },
    {
        title: "Investigate",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: true,
        status: 'failed',
        createdAt: '2021-06-01T00:00:00.000+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
    {
        title: "Procedure",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: false,
        status: 'sent',
        createdAt: '2021-06-11T10:52:21.719+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
    {
        title: "Birthday Message",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: false,
        status: 'delivered',
        createdAt: '2023-10-26T08:52:11.416+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
    {
        title: "Appreciation Message",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: false,
        status: 'sent',
        createdAt: '2021-11-11T00:00:00.000+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
    {
        title: "Wallet Deduction",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: false,
        status: 'delivered',
        createdAt: '2022-04-14T00:00:00.000+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
    {
        title: "End Consultation",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: false,
        status: 'delivered',
        createdAt: '2022-04-14T00:00:00.000+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
    {
        title: "Christmas Message",
        body: "Dear Firstname, We would like to wish you happy birthday and many happy returns from all of us at Layor Hospital",
        isSystemMessage: false,
        status: 'delivered',
        createdAt: '2022-03-27T14:30:36.790+00:00',
        recipient: {
            name: "Kristin Watson",
            phoneNumber: "09124568976"
        },
        amount: 4000
    },
]

export const SMS_VARIABLES = [
    "{first_name}",
    "{last_name}",
    "{medical_record_number}",
    "{phone_number}",
    "{feedback_link}",
    "{hospital_name}",
    "{contact_info}",
    "{appointment_date}",
    "{appointment_time}",
    "{hospital_location}",
]

export const PURPOSE_OPTIONS = [
    { label: "Festival", value: "Festival" },
    { label: "Birthday", value: "Birthday" },
    { label: "Registration", value: "Registration" },
    { label: "Appointment visit", value: "Appointment visit" },
    { label: "Appointment completion", value: "Appointment completion" },
    { label: "Book Surgery", value: "Book Surgery" },
    { label: "Book Procedure", value: "Book Procedure" },
    { label: "Recommend Admission", value: "Recommend Admission" },
    { label: "Discharge", value: "Discharge" },
    { label: "Drug Refill", value: "Drug Refill" },
    { label: "Prescription", value: "Prescription" },
    { label: "Med docket", value: "Med docket" },
    { label: "Recall", value: "Recall" },
    { label: "Payment completion", value: "Payment completion" },
    { label: "Patient Credit", value: "Patient Credit" },
    { label: "Lab Result", value: "Lab Result" },
]

export const SMS_FREQUECY = {
    "Festival": [
        { label: "Festival", value: "Festival" },
        { label: "Yearly", value: "Yearly" },
    ],
    "Birthday": [
        { label: "Yearly", value: "Yearly" },
        { label: "Birthday", value: "Birthday" },
    ],
    "Appointment visit": [
        { label: "2hr before Appointment date", value: "2hr before Appointment date" },
        { label: "8hr before Appointment date", value: "8hr before Appointment date" },
        { label: "24hr before Appointment date", value: "24hr before Appointment date" },
        { label: "48hr before Appointment date", value: "48hr before Appointment date" },
    ],
    "Recommend Surgery": [
        { label: "2hr before surgery date", value: "2hr before surgery date" },
        { label: "8hr before surgery date", value: "8hr before surgery date" },
        { label: "24hr before surgery date", value: "24hr before surgery date" },
        { label: "48hr before surgery date", value: "48hr before surgery date" },
    ],
    "Recommend Procedure": [
        { label: "2hr before procedure date", value: "2hr before procedure date" },
        { label: "8hr before procedure date", value: "8hr before procedure date" },
        { label: "24hr before procedure date", value: "24hr before procedure date" },
        { label: "48hr before procedure date", value: "48hr before procedure date" },
    ],
    "Recommend Admission": [
        { label: "2hr before checkin date", value: "2hr before checkin date" },
        { label: "8hr before checkin date", value: "8hr before checkin date" },
        { label: "24hr before checkin date", value: "24hr before checkin date" },
        { label: "48hr before checkin date", value: "48hr before checkin date" },
    ],
    "Drug Refill": [
        { label: "2hr before refill date", value: "2hr before refill date" },
        { label: "8hr before refill date", value: "8hr before refill date" },
        { label: "24hr before refill date", value: "24hr before refill date" },
        { label: "48hr before refill date", value: "48hr before refill date" },
    ],
    "Recall": [
        { label: "Recall", value: "Recall" },
    ],
    "Payment completion": [
        { label: "Payment completion", value: "Payment completion" },
    ],
    "Patient Credit": [
        { label: "Patient Credit", value: "Patient Credit" },
    ],
    "Lab Result": [
        { label: "Lab Result", value: "Lab Result" },
    ],
}
