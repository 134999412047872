import React from "react";
import { Toaster as Sonner } from "sonner";

const Toaster = ({ ...props }) => {
  return (
    <Sonner
      className="toaster tw-group"
      toastOptions={{
        classNames: {
          toast:
            "tw-group tw-z-100 tw-toast tw-group-[.toaster]:tw-bg-background tw-group-[.toaster]:tw-text-foreground tw-group-[.toaster]:tw-border-border " +
            "tw-group-[.toaster]:tw-shadow-lg",
          description: "tw-group-[.toast]:tw-text-muted-foreground",
          actionButton: "tw-group-[.toast]:tw-bg-primary tw-group-[.toast]:tw-text-primary-foreground",
          cancelButton: "tw-group-[.toast]:tw-bg-muted tw-group-[.toast]:tw-text-muted-foreground",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
