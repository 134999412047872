import { _notifyError, _notifySuccess, printError } from "utils";
import Axios from "services/axios";
import * as subscriptionTypes from "../types/subscriptionTypes";

export const fetchSubscriptions = () => async (dispatch) => {
  try {
    dispatch({
      type: subscriptionTypes.FETCH_SUBSCRIPTIONS_START,
    });
    const { data } = await Axios.get(`/subscriptions`);

    if (!data.status) {
      _notifyError(data.message);
    } else {
      dispatch({
        type: subscriptionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
        payload: data.data,
      });
    }
  } catch (err) {
    const msg = err?.response?.data?.message || `An error occurred. Please try again`;
    _notifyError(msg);
  }
};

export const fetchPayments = (queryParams) => async (dispatch) => {
  dispatch({
    type: subscriptionTypes.FETCH_PAYMENTS_START,
  });
  const { data } = await Axios.get(`/payments`, { params: queryParams });

  if (!data.status) {
    _notifyError(data.message);
  } else {
    dispatch({
      type: subscriptionTypes.FETCH_PAYMENTS_SUCCESS,
      payload: data.data,
    });
  }
};

export const cancelSubscription = (id, close) => async (dispatch) => {
  dispatch({
    type: subscriptionTypes.CANCEL_SUBSCRIPTION_START,
  });
  try {
    await Axios.patch(`/subscriptions/${id}/cancel`);
    close?.();
  } catch (error) {
    const errorMessage = printError(error);
    _notifyError(errorMessage);
  }
  dispatch({
    type: subscriptionTypes.CANCEL_SUBSCRIPTION_END,
  });
};

/**
 * @description adds a card to an organization's payment methods
 * @returns {String} authorization url (or undefined if there's an error)
 */
export const addCard = async () => {
  try {
    const response = await Axios.post(`/cards`);
    let auth_url = undefined;
    if (response && response.data.status) {
      auth_url = response.data?.data?.authorization_url;
      return auth_url;
    }
  } catch (err) {
    const msg = err?.response?.data?.message || `An error occurred. Please try again`;
    _notifyError(msg);
  } finally {
    Axios.post(`/logout`);
  }
};

export const fetchCards = () => async (dispatch) => {
  try {
    dispatch({
      type: subscriptionTypes.FETCH_CARDS_START,
    });
    const { data } = await Axios.get(`/cards`);

    if (!data.status) {
      _notifyError(data.message);
    } else {
      dispatch({
        type: subscriptionTypes.FETCH_CARDS_SUCCESS,
        payload: data.data,
      });
    }
  } catch (err) {
    const msg = err?.response?.data?.message || `An error occurred. Please try again`;
    _notifyError(msg);
  }
};

export const paySubscription = async () => {
  try {
    const response = await Axios.post(`/payments/initialize`);
    let auth_url = undefined;
    if (response && response.data.status) {
      auth_url = response.data?.data?.authorization_url;
      return auth_url;
    }
  } catch (err) {
    const msg = err?.response?.data?.message || `An error occurred. Please try again`;
    _notifyError(msg);
  } finally {
    //
  }
};

export const purchaseCredit = async (payload, callback) => {
  await Axios.post(`/payments/credits-topup`, payload)
    .then((res) => {
      if (res.data.status) {
        callback?.();
        _notifySuccess("Top up was successful");
      }
    })
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message || error.response.data
        : "Top up was unsuccessful";
      _notifyError(errorMessage);
    });
};

export const purchaseLicenses = async (payload, callback) => {
  try {
    await Axios.post(`/payments/licenses-topup`, payload);
  } catch (err) {
    const errorMessage = err.response ? err.response.data.message || err.response.data : "Top up was unsuccessful";
    _notifyError(errorMessage);
  } finally {
    callback?.();
  }
};
