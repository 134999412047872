import React from "react";
import PropTypes from "prop-types";
import { Button } from "./ui/buttons/button";
import { cn } from "utils";

export const ViewSwitcher = ({ config = [], toggleViewSwitch, activeView }) => {
  return (
    <div className="tw-flex tw-items-center">
      {config.map((item, index) => (
        <Button
          key={index}
          modifier={item.id === activeView && "outline"}
          onClick={() => toggleViewSwitch(item.id)}
          className={cn(`tw-rounded-none first:tw-rounded-l last:tw-rounded-r tw-h-[39px] tw-w-[60px]`)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

ViewSwitcher.propTypes = {
  activeView: PropTypes.string,
  config: PropTypes.array,
  toggleViewSwitch: PropTypes.func,
};
