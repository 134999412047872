import { _notifyError, _notifySuccess, _sortItems } from "utils";
import * as PlansTypes from "../types/plansTypes";
import { beginApiCall, endApiCall } from "../../../../store/actions/apiActions";
import Axios from "services/axios";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const updatedState = (data) => ({
  isSaving: false,
  error: "",
  items: data,
  filteredItems: data,
});

const fetchPlansSuccess = (payload) => ({
  type: PlansTypes.FETCH_PLANS_SUCCESS,
  payload,
});

const fetchPlansFailed = () => ({
  type: PlansTypes.FETCH_PLANS_FAILED,
});

const addPlansStart = () => ({
  type: PlansTypes.ADD_PLANS_START,
});

const addPlansSuccess = (payload) => ({
  type: PlansTypes.ADD_PLANS_SUCCESS,
  payload,
});

const addPlansFailed = () => ({
  type: PlansTypes.ADD_PLANS_FAILED,
});

const updatePlansStart = (payload) => ({
  type: PlansTypes.UPDATE_PLANS_START,
  payload,
});

const updatePlansSuccess = (payload) => ({
  type: PlansTypes.UPDATE_PLANS_SUCCESS,
  payload,
});

// const updatePlansFailed = (payload) => ({
//   type: PlansTypes.UPDATE_PLANS_FAILED,
//   payload,
// });

const deletePlansStart = (payload) => ({
  type: PlansTypes.DELETE_PLANS_START,
  payload,
});

const deletePlansSuccess = (payload) => ({
  type: PlansTypes.DELETE_PLANS_SUCCESS,
  payload,
});

const deletePlansFailed = (payload) => ({
  type: PlansTypes.DELETE_PLANS_FAILED,
  payload,
});

export const fetchPlans = () => async (dispatch) => {
  dispatch(beginApiCall());
  try {
    const { data } = await Axios.get("plans");
    if (data.status === "true") {
      const payload = _sortItems(data.data, "name");
      // throw Error;
      dispatch(endApiCall());
      dispatch(fetchPlansSuccess(updatedState(payload)));
      return true;
    }
    throw Error;
  } catch (err) {
    dispatch(endApiCall());
    _notifyError(err.message);
    dispatch(fetchPlansFailed(err.message));
    return false;
  }
};

export const addPlans = (current, arr, close) => {
  return async (dispatch) => {
    dispatch(addPlansStart());
    try {
      const { data, status } = await Axios.post("plans", current);

      if (status) {
        const cache = _sortItems([...arr, data.data], "name");
        dispatch(addPlansSuccess(updatedState(cache)));
        _notifySuccess("Patient Plan successfully added.");
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(addPlansFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updatePlans = (current, arr, close, action) => {
  return async (dispatch) => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { name, branches, isActive } = current;

    dispatch(updatePlansStart(actionType));
    try {
      const { status } = await Axios.patch(`plans/${current._id}`, {
        isActive,
        name,
        branches,
      });

      if (status) {
        const updatedPlans = [...arr].map((prev) => (String(prev._id) === String(current._id) ? current : prev));
        const cache = _sortItems(updatedPlans, "name");
        dispatch(updatePlansSuccess(cache));
        _notifySuccess(`Patient Plan ${action || "updated"} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      throw error;
      // dispatch(updatePlansFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updatePlansList = (value) => ({
  type: PlansTypes.UPDATE_PLANS_LIST,
  payload: {
    filteredItems: value,
  },
});

export const deletePlans = (id, toggle, toggleViewModal) => {
  return async (dispatch) => {
    dispatch(deletePlansStart());
    try {
      const { status } = await Axios.delete("deletePlans", [id]);

      if (status) {
        toggle();
        toggleViewModal();
        dispatch(deletePlansSuccess(id));

        _notifySuccess(`Patient Plan Deleted successfully.`);
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(deletePlansFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};
