import React from "react";

export function Move(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7757_4424)">
        <path
          d="M3.33333 5.9987L1.33333 7.9987M1.33333 7.9987L3.33333 9.9987M1.33333 7.9987H14.6667M6 3.33203L8 1.33203M8 1.33203L10 3.33203M8 1.33203V14.6654M10 12.6654L8 14.6654M8 14.6654L6 12.6654M12.6667 5.9987L14.6667 7.9987M14.6667 7.9987L12.6667 9.9987"
          stroke="#15152E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7757_4424">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
