import { _notifyError, _notifySuccess, _sortItems, updatedState } from "utils";
import * as roleTypes from "../types/roleTypes";
import Axios from "services/axios";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const addRoleStart = () => ({ type: roleTypes.ADD_ROLE_START });

const addRoleSuccess = (payload) => ({
  type: roleTypes.ADD_ROLE_SUCCESS,
  payload,
});

const addRoleFailed = (msg) => ({
  type: roleTypes.ADD_ROLE_FAILED,
  error: msg,
});

export const addRole = (current, arr, close) => {
  return async (dispatch) => {
    dispatch(addRoleStart());

    try {
      const { data } = await Axios.post("/roles", current);

      if (data.status) {
        const cache = _sortItems([...arr].concat(data.data), "title");
        dispatch(addRoleSuccess(updatedState(cache)));
        _notifySuccess("Role successfully added.");
        close();
      }
    } catch ({ response }) {
      const errMsg = typeof response?.data === "string" ? response.data : response?.data?.message || "";
      dispatch(addRoleFailed(errMsg || CUSTOM_ERROR_MESSAGE));
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const deleteRoleStart = () => ({
  type: roleTypes.DELETE_ROLE_START,
});

const deleteRoleSuccess = (payload) => ({
  type: roleTypes.DELETE_ROLE_SUCCESS,
  payload,
});

const deleteRoleFailed = (msg) => ({
  type: roleTypes.DELETE_ROLE_FAILED,
  error: msg,
});

export const deleteRole = (id, arr, close) => {
  return async (dispatch) => {
    dispatch(deleteRoleStart());
    try {
      const { data } = await Axios.patch(`/roles/${id}`);
      if (data.status) {
        const cache = [...arr].filter((prev) => String(prev._id) !== String(id));

        dispatch(
          deleteRoleSuccess({
            items: cache,
            filteredItems: cache,
          }),
        );
        _notifySuccess(`Role deleted successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(deleteRoleFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const rolesPageSort = (arr) => {
  return {
    type: roleTypes.ROLES_PAGE_SORT,
    payload: {
      items: arr,
      filteredItems: arr,
    },
  };
};

export const updateRoleList = (value) => ({
  type: roleTypes.UPDATE_ROLES_LIST,
  payload: {
    filteredItems: value,
  },
});
