import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormInput, Select } from "components";
import classnames from "classnames";
import { ORDER_STATE_OPTIONS } from "utils/constants";
import { _formatDate, populateUsersBranches } from "utils";
import { Link } from "react-router-dom";
import { Button } from "components/ui/buttons/button";
import { DatePickerInput } from "components/ui/date-picker-input";

export const GeneratedReport = ({ staffList, branches }) => {
  const initialValue = {
    title: "",
    branch: "",
    orderStatus: undefined,
    operator: undefined,
    fromDate: undefined,
    toDate: undefined,
  };
  const [state, setState] = useState(initialValue);
  const { branch, orderStatus, operator, fromDate, toDate, title } = state;
  const handleAttributeChange = (value, attribute) => {
    let extraField = "";
    if (attribute === "fromDate" && toDate) {
      const to = new Date(toDate).getTime();
      const from = new Date(value).getTime();
      if (to - from < 0 || from - to > 0) {
        extraField = { toDate: undefined };
      }
    }

    setState({
      ...state,
      [attribute]: ["fromDate", "toDate"].includes(attribute) ? _formatDate(new Date(value), "YYYY-MM-DD") : value,
      ...extraField,
    });
  };

  const _isDisabled = () => {
    if (fromDate && toDate === undefined) return true;
    if (!branch) return true;
    return !(branch || orderStatus || operator || (fromDate && toDate));
  };

  return (
    <div className="card card__report">
      <div className="form-header container-fluid">
        <h3 className="form-header__title">Generate Report</h3>
        <h4 className="form-header__subtitle">Select up to two filter option to start your search</h4>
      </div>
      <div className="container-fluid">
        <form onSubmit={(e) => e.preventDefault()} className="form__settings">
          <div className="tw-grid tw-grid-cols-12 tw-gap-10">
            <div className="tw-col-span-12">
              <Select
                isMulti={false}
                isRequired
                label="Branch"
                placeholder="Select Branch"
                options={populateUsersBranches(branches)}
                onChange={(e) => handleAttributeChange(e, "branch")}
                value={branch || populateUsersBranches(branches)[0]}
              />
            </div>
            <div className="tw-col-span-12">
              <Select
                isMulti={false}
                label="Order Status"
                placeholder="Select Order Status"
                options={ORDER_STATE_OPTIONS}
                onChange={(e) => handleAttributeChange(e, "orderStatus")}
                value={orderStatus}
              />
            </div>
            <div className="tw-col-span-12">
              <Select
                isMulti={false}
                label="Operator"
                options={staffList}
                placeholder="Select Operator"
                onChange={(e) => handleAttributeChange(e, "operator")}
                value={operator}
              />
            </div>
            <div className="tw-col-span-12 md:tw-col-span-6">
              <DatePickerInput
                label="Date From"
                id="fromDate"
                value={fromDate}
                onDayChange={(e) => handleAttributeChange(e, "fromDate")}
              />
            </div>
            <div className="tw-col-span-12 md:tw-col-span-6">
              {fromDate && (
                <DatePickerInput
                  label="Date To"
                  disabled={{
                    before: fromDate ? new Date(fromDate) : undefined,
                  }}
                  id="toDate"
                  value={toDate}
                  datePickerProps={{ disabled: fromDate === undefined }}
                  onDayChange={(e) => handleAttributeChange(e, "toDate")}
                />
              )}
            </div>
            <div className="tw-col-span-12">
              <FormInput
                id="title"
                label="Title"
                placeholder="Enter title"
                type="text"
                value={title}
                onChange={(e) => handleAttributeChange(e.target.value, "title")}
              />
            </div>
          </div>

          <div className="form__settings-footer">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
              <div className="form__settings-button">
                <Button
                  variant="primary"
                  className={classnames("btn__secondary", {
                    btn__loading: false,
                  })}
                  loading={false}
                  download
                  disabled={_isDisabled()}
                >
                  {_isDisabled() ? (
                    `Get Report`
                  ) : (
                    <Link
                      to={{
                        pathname: `report/generated-list`,
                        state: { ...state, staffList, branches },
                      }}
                    >
                      {" "}
                      Get Report{" "}
                    </Link>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

GeneratedReport.propTypes = {
  staffList: PropTypes.array,
  branches: PropTypes.array,
};
