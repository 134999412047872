import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import InfiniteScroll from "../InfiniteScroll";
import { ConfirmModal, Loader, PendingModal } from "components";
import { TaskContainer } from "../../components";
import {
  assignTask,
  extendDueDate,
  getActiveTask,
  startTaskAction,
  unassignTask,
} from "modules/laboratory/redux/actions";
import { PENDING } from "utils/constants";
import { _formatDate } from "utils";

export const PendingTasks = ({
  acceptedTasks,
  staffList,
  hasPermission,
  activeTask,
  inProgress,
  isSaving,
  refresh,
}) => {
  const [pendingModal, setPendingModal] = useState(false);
  const [startModal, setStartModal] = useState(false);

  const dispatch = useDispatch();

  const togglePendingModal = () => {
    setPendingModal(!pendingModal);
  };

  const toggleStartModal = () => {
    setStartModal(!startModal);
  };

  const changeDueDate = (newDate) => {
    const response = window.confirm(`Are you sure you want to extend the due date to ${_formatDate(newDate)}`);
    if (response) return dispatch(extendDueDate(activeTask._id, newDate));
  };
  const _handleTaskAssign = (item) => {
    const isAssigned = activeTask.assignees.some((person) => person._id === item.id);
    if (isAssigned) return window.alert("Staff already assigned to this task");
    const task = activeTask._id;
    const staff = item._id;
    dispatch(assignTask(task, staff));
  };
  const startTask = () => {
    dispatch(startTaskAction(activeTask._id, toggleStartModal));
  };
  const _deleteAssignee = (id) => {
    const response = window.confirm("Are you sure you want to remove this staff from the assigned task?");
    if (response) {
      dispatch(unassignTask(activeTask._id, id));
    }
  };
  const triggerStartTask = () => {
    togglePendingModal();
    toggleStartModal();
  };

  const viewPendingTask = (task) => {
    dispatch(getActiveTask(task));
    togglePendingModal();
  };

  return (
    <>
      <ConfirmModal
        isSaving={isSaving}
        isOpen={startModal}
        toggle={toggleStartModal}
        positiveClick={startTask}
        negativeClick={toggleStartModal}
        color="purple"
        title="Start?"
        subtitle="Are you sure you want to start this task?"
        positiveText="Yes"
        negativeText="No"
      />
      <PendingModal
        inProgress={inProgress}
        searchData={staffList}
        isOpen={pendingModal}
        toggle={togglePendingModal}
        rightButtonPress={triggerStartTask}
        rightButtonDisabled={!hasPermission(["start-job", "super-edit"])}
        deleteAssignee={_deleteAssignee}
        permitted={hasPermission(["assign-user", "super-edit"])}
        _handleTaskAssign={_handleTaskAssign}
        allowDateEdit
        showDeadline
        changeDueDate={changeDueDate}
        isSubmitting={isSaving}
        entity={activeTask}
      />
      {acceptedTasks?.isLoading ? (
        <Loader />
      ) : (
        <TaskContainer items={acceptedTasks?.docs?.length || 0} title="Pending" showDateRange={false} refresh={refresh}>
          <InfiniteScroll
            taskObject={acceptedTasks}
            dispatch={dispatch}
            taskStatus={PENDING}
            viewSingleTask={viewPendingTask}
          />
        </TaskContainer>
      )}
    </>
  );
};

PendingTasks.propTypes = {
  acceptedTasks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  staffList: PropTypes.array,
  hasPermission: PropTypes.func,
  isSaving: PropTypes.bool,
  activeTask: PropTypes.object,
  inProgress: PropTypes.bool,
  refresh: PropTypes.func,
};
