import React from 'react';
import PropTypes from 'prop-types';
import { ModalSearch } from 'components';
import { Button } from 'components/ui/buttons/button';
import { UserCard } from 'components/usercard';
import { Modal } from './index';

const Footer = (props) => (
  <>
    <Button
    
      disabled={props.rightButtonDisabled}
      onClick={props.rightButtonPress}
      className="modal__btn modal__btn--start"
    >
      Start
    </Button>
  </>
);

Footer.propTypes = {
  rightButtonPress: PropTypes.func,
  rightButtonDisabled: PropTypes.bool,
};

export const PendingModal = ({ ...props }) => {
  return (
    <Modal color="purple" {...props} footer={<Footer {...props} />}>
      <div className="modal__list__item">
        {props.permitted ? (
          <>
            <p className="modal__list__text modal__list__text--1">Assign to others</p>
            <ModalSearch {...props} />
          </>
        ) : null}
        <div className="modal__list__text modal__list__text--2">
          <div className="row">
            {props?.entity?.assignees.map(
              (ls) =>
                ls.name && (
                  <div className="col-sm-6" key={ls._id}>
                    <UserCard label {...ls} showDelete deleteAssignee={props.deleteAssignee} />
                  </div>
                ),
            )}
            {props.inProgress && (
              <>
                <div className="page-loader__dot red" /> <div className="page-loader__dot red" />
                <div className="page-loader__dot red" />
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
PendingModal.defaultProps = {
  assignees: [],
  permitted: false,
};

PendingModal.propTypes = {
  deleteAssignee: PropTypes.func,
  entity: PropTypes.object,
  permitted: PropTypes.bool,
  inProgress: PropTypes.bool,
};
