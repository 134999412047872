import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { AuthLayout, MainLayout, SupportLayout } from '../layouts';

import NonauthRoute from './NonauthRoute';
import AuthRoute from './AuthRoute';

const Login = lazy(() => import('pages/auth/Login'));
const SelectBranch = lazy(() => import('pages/SelectBranch'));
const Reset = lazy(() => import('pages/auth/Reset'));
const ChangePassword = lazy(() => import('pages/auth/ChangePassword'));
const TermsAndPrivacy = lazy(() => import('pages/TermsAndPrivacy'));
const UserProfile = lazy(() => import('pages/app/UserProfile'));
const Support = lazy(() => import('pages/app/Support'));
const Unsubscribe = lazy(() => import('pages/Unsubscribe'));

// const ErrorPage = lazy(() => import('pages/Error'));

const PublicRoute = ({ match }) => {
  const { loggedIn } = useSelector(state => state.auth);
  return (
    <>
      <Switch>
        <NonauthRoute isAuthenticated={false} path={`${match.path}unsubscribe`} component={Unsubscribe} layout={AuthLayout} />
        <NonauthRoute isAuthenticated={loggedIn} path={`${match.path}reset`} component={Reset} layout={AuthLayout} />
        <NonauthRoute isAuthenticated={loggedIn} path={`${match.path}login`} component={Login} layout={AuthLayout} />
        <NonauthRoute
          isAuthenticated={loggedIn}
          path={`${match.path}terms-and-privacy`}
          component={TermsAndPrivacy}
          layout={AuthLayout}
          // pageClass="p-top-0"
          displayShapes={false}
          cardClass="auth__terms--card"
        />

        <NonauthRoute
          isAuthenticated={loggedIn}
          path={`${match.path}reset-password`}
          component={ChangePassword}
          layout={AuthLayout}
        />

        <AuthRoute
          isAuthenticated={loggedIn}
          path={`${match.path}select-branch`}
          component={SelectBranch}
          layout={AuthLayout}
          accessGranted={true}
        />

        <AuthRoute
          isAuthenticated={loggedIn}
          path={`${match.path}profile`}
          component={UserProfile}
          layout={MainLayout}
          accessGranted={true}
        />

        <AuthRoute
          isAuthenticated={loggedIn}
          path={`${match.path}support`}
          component={Support}
          layout={SupportLayout}
          accessGranted={true}
        />
        {/* <NonauthRoute
          exact
          path="*"
          isAuthenticated={loggedIn}
          layout={AuthLayout}
          component={NotFound}
        /> */}
      </Switch>
    </>
  );
};

PublicRoute.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default PublicRoute;
