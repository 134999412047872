import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, printError } from "utils";

const formEntryApi = createApi({
  reducerPath: "formEntryApi",
  baseQuery,
  // tagTypes: ["Form-Entry"],
  endpoints: (builder) => ({
    fetchEntries: builder.query({
      query: (queryParams) => ({
        url: `/form-entries`,
        params: queryParams,
      }),
      providesTags: ["Form-Entries"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchEntry: builder.query({
      query: ({ formId, ...queryParams }) => ({
        url: `/form-entries/${formId}`,
        params: queryParams,
      }),
      providesTags: ["Form-Entry"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    recordNewEntry: builder.mutation({
      query: ({ formId, payload }) => ({
        url: `/forms/${formId}/entries`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          close?.();
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Form-Entries", "Form-Entry"],
    }),
  }),
});

export const { useRecordNewEntryMutation, useFetchEntriesQuery, useFetchEntryQuery } = formEntryApi;

export default formEntryApi;
