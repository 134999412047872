import * as React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { cn } from "utils";
import { Button } from "components/ui/buttons/button";
import { Calendar } from "components/ui/calendar";
import Required from "components/required";
import { Popover, PopoverDialogContent, PopoverTrigger } from "components/ui/popover";

export function DatePickerInput({ onDayChange, value, label, buttonClass, isDisabled = false, mode = "single", ...props }) {
  return (
    <Popover modal={true} className="tw-relative tw-w-full">
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          disabled={isDisabled}
          className={cn(
            "md:tw-w-full tw-flex tw-h-[68px] tw-items-center tw-border-[#CED6DE] tw-border tw-justify-start " +
              "tw-text-left tw-font-normal tw-px-6 tw-rounded-[4px]",
            !value && "text-muted-foreground",
            buttonClass,
          )}
        >
          <div className="tw-flex tw-items-end tw-justify-between tw-w-full ">
            <div className="">
              <p className="tw-text-natural-500 tw-text-[12px] tw-mb-0.5">
                {label} {props.isRequired && <Required />}
              </p>
              {value ? format(value, "PPP") : <span className="tw-text-natural-400">DD/MM/YYYY</span>}
            </div>

            <i className="iconmoon icon-calendar tw-mb-1.5" />
          </div>
        </Button>
      </PopoverTrigger>

      <PopoverDialogContent className="tw-w-auto">
        <Calendar
          selected={value}
          defaultMonth={value}
          onSelect={(e) => {
            onDayChange(e);
          }}
          mode={mode}
          initialFocus
          {...props}
        />
      </PopoverDialogContent>
    </Popover>
  );
}

DatePickerInput.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  buttonClass: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  modal: PropTypes.bool,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  isAppointment: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  dayPickerProps: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  currentYear: PropTypes.number,
  toMonth: PropTypes.object,
  fromMonth: PropTypes.object,
  fromYear: PropTypes.number,
  disabledDays: PropTypes.object,
  onDayChange: PropTypes.func,
  dateFormat: PropTypes.string,
  modifiers: PropTypes.object,
  className: PropTypes.string,
  mode: PropTypes.string,
};
