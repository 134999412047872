import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

export const DashboardHeader = ({ ...props }) => {
  const { title, subtitle, rightContent: Content, className } = props;
  return (
    <div
      className={`dashboard-header tw-flex flex-column flex-sm-row justify-content-between align-items-center ${className}`}
    >
      <div>
        <h3 className="tw-text-2xl tw-font-semibold tw-text-gray-900 mb-3 mb-sm-0 ">{title}</h3>
        {props.subtitle && <h4 className="dashboard-header__subtitle">{subtitle}</h4>}
      </div>
      {Content && <Content />}
    </div>
  );
};

DashboardHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rightContent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
};

export const DashboardHeaderLink = (props) => {
  const { title, left, onClick } = props;

  return (
    <div className={`tw-flex justify-content-${left ? "start" : "end"} align-items-center `}>
      <div className="dashboard-header-link" onClick={onClick}>
        {left ? (
          <>
            <span className="mr-3">
              <i className="fas fa-long-arrow-alt-left"></i>
            </span>
            {<span className="dashboard-header-link-text"> {title} </span>}
          </>
        ) : (
          <>
            {<span className="dashboard-header-link-text"> {title} </span>}
            <span className="ml-3">
              <i className="fas fa-long-arrow-alt-right"></i>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

DashboardHeaderLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  left: PropTypes.bool,
  onClick: PropTypes.func,
};

export const DashboardHeaderGoBack = (props) => {
  const { text, margin, onClick } = props;
  const history = useHistory();
  return (
    <div onClick={() => (onClick ? onClick() : history.goBack())} className={`dashboard-header-goback ${margin || ""}`}>
      <i className="iconmoon icon-go-back tw-mr-4" />
      <p className="paragraph-big tw-font-medium color-dark tw-whitespace-nowrap">{text || "Go Back"}</p>
    </div>
  );
};

DashboardHeaderGoBack.propTypes = {
  text: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
};
