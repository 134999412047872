import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as DeleteAssignee } from 'assets/svg/delete.svg';

export const UserCard = props => {
  if (!props.label) {
    return (
      <div className={classnames('card card__user', props.className)}>
        {props.noAvatar ? null : (
          <figure className="card__user__avatar">
            <img alt="avatar" src={props.avatar} />
          </figure>
        )}
        <div className="card__user__details">
          <h3 className="card__user__title">{props.name.length > 10 ? (props?.name?.substr(0, 7) + '...') : (props.name)}</h3>
          <p className="card__user__subtitle">{props.email}</p>
        </div>
      </div>
    );
  }
  return (
    <div
      className={classnames(
        'card card__user card__user--label',
        props.className
      )}
    >
      {props.showDelete ? (
        <span
          onClick={() => props.deleteAssignee(props._id)}
          className="delete-assignee-icon"
        >
          <DeleteAssignee />
        </span>
      ) : null}
      <div className="card__user__details">
        <h3 className="card__user__title">{props.name.length > 20 ? (props?.name?.substr(0, 17) + '...') : (props.name)}</h3>
        <p className="card__user__subtitle">{props.email}</p>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.bool,
  noAvatar: PropTypes.bool,
  showDelete: PropTypes.bool,
  deleteAssignee: PropTypes.func,
  email: PropTypes.string,
  name: PropTypes.string,
  _id: PropTypes.string
};

UserCard.defaultProps = {
  label: false,
  showDelete: false,
  name: 'Dante Lex'
};
