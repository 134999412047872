/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React from "react";
import csv2json from "csvjson-csv2json";
import moment from "moment";
import moment_time from "moment-timezone";
import { toast } from "react-toastify";
import { concat } from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";
import { CUSTOM_ERROR_MESSAGE, EXTRA_DETAILS, HMO_DETAILS, NEXT_OF_KIN, PERSONAL_DETAILS, TUNES } from "./constants";
import { setupOptions } from "config/nav-options";
import { compressAccurately } from "image-conversion";
import { extendTailwindMerge } from "tailwind-merge";
import classnames from "classnames";
import Axios from "services/axios";
import { useFormContext, useWatch } from "react-hook-form";

export const customTwMerge = extendTailwindMerge({
  prefix: "tw-",
  // ↓ Add values to existing theme scale or create a new one
  theme: {
    spacing: ["sm", "md", "lg"],
    colors: {
      primary: {
        20: "#FCFCFF",
        50: "#F2F2FC",
        100: "#E1E1FA",
        500: "#6A69E4",
        600: "#5554B6",
        700: "#403F89",
      },
      destructive: {
        100: "#FFF0F2",
        200: "#FCC5CE",
        500: "#D62F4B",
        600: "#AD283D",
        700: "#842432",
      },
      natural: {
        "": "#FFFFFF",
        100: "#F5F8FA",
        400: "#B8C4CE",
        700: "#842432",
      },
    },
  },
});

export function cn(...inputs) {
  return customTwMerge(classnames(...inputs));
}

export function isCamelCase(str) {
  return /^[a-z]+(?:[A-Z][a-z]*)*$/.test(str);
}

export function printError(error, defaultError = CUSTOM_ERROR_MESSAGE) {
  return error
    ? error?.response?.data?.message ||
        error?.error?.data ||
        error?.error?.data?.message ||
        error?.data?.message ||
        error?.response?.data
    : defaultError;
}

export function toCamelCase(inputString) {
  const isCamelCasedString = /[A-Z]/.test(inputString);
  if (!inputString) return "";
  if (!isCamelCasedString) return inputString;
  const trimmedString = inputString.toLowerCase().trim();
  return trimmedString
    .split(" ")
    .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join("");
}

export function toUnderscoreCase(inputString) {
  return inputString.toLowerCase().replace(/\s/g, "_");
}

export const _joinArr = (arr = [], ellipsis = false) => {
  if (arr.length < 2) {
    return arr.join(" and ");
  } else if (ellipsis) {
    const array = arr.slice(0, -1);
    return `${array.join(", ")}, ${arr[arr.length - 1]}...`;
  }
  const array = arr.slice(0, -1);
  return `${array.join(", ")} and ${arr[arr.length - 1]}`;
};

export const isDisabledButton = (dischargeDate, thresholdDays) => {
  let disabled = false;
  if (moment(new Date(dischargeDate)).add(thresholdDays, "days") < new Date()) {
    disabled = true;
  }
  return disabled;
};

export const filterKeys = (data, requiredKeys) => {
  const filterObj = (obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      if (requiredKeys.includes(key) && !["", [], {}].includes(value)) {
        return { ...acc, [key]: value };
      }
      return { ...acc };
    }, {});

  if (Array.isArray(data)) {
    return data.reduce((acc, obj) => {
      const newObj = filterObj(obj);
      return [...acc, newObj];
    }, []);
  }

  return filterObj(data);
};

export const _arrayObjectToString = (arr, label) => {
  if (!Array.isArray(arr)) return arr;

  const temp = arr.map((arrayObject) => arrayObject[label]);
  return _joinArr(temp);
};

export const branchRole = (branch) => {
  const roles = branch.roles.map((roleItem, index) => {
    return (
      <span className="modal__list__text--pill p-2 mr-2" key={index}>
        {roleItem.title}
      </span>
    );
  });
  const modules = branch.modules.map((moduleItem, index) => {
    return (
      <span className="modal__list__text--pill p-2 mr-2" key={index}>
        {moduleItem || "Not Provider"}
      </span>
    );
  });

  return (
    <>
      <div className="mb-3" key={branch._id}>
        <p className="mb-2">{branch.name}</p>
        {roles}
        {modules.length > 0 && (
          <div className="mt-4">
            <p className="modal__list__text modal__list__text--1">* Modules</p>
            {modules}
          </div>
        )}
      </div>
    </>
  );
};

export const _autocomplete = (value, arr, field) => {
  const inputValue = value.trim()?.toLowerCase();
  return inputValue.length === 0 ? [] : arr.filter((item) => item[field]?.toLowerCase().indexOf(inputValue) !== -1);
};

export const _breakItemsIntoPage = (array, pageLimit = 10) => {
  let index = 0;
  const pageLength = Math.ceil(array.length / pageLimit);
  const temp = [];

  for (let i = 0; i < pageLength; i++) {
    const total = index + pageLimit;
    const result = array.slice(index, total);
    temp.push(result);
    index = total;
  }
  return temp;
};

export const _firstLetterUc = (string) => {
  if (string) {
    return string.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  }
};

export function camelCaseToWords(camelCaseString) {
  const isCamelCasedString = /[A-Z]/.test(camelCaseString);
  if (!camelCaseString || !isCamelCasedString) return _firstLetterUc(camelCaseString);
  return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .replace(/(^|\s)([a-z])/g, (match, group1, group2) => group1 + group2.toUpperCase());
}

export const _renderPills = (arr, title, badge = true) => {
  let badgeText;

  if (arr && arr.length > 0) {
    badgeText = (
      <>
        <span className={badge ? "table__badge" : ""}>{_firstLetterUc(arr[0].name)}</span>
        {arr.length > 2 ? <span className="table__badge-number">{`+${arr.length - 1}`}</span> : null}
      </>
    );
  } else {
    badgeText = <span className={badge ? "table__badge" : ""}>{`No ${title}`}</span>;
  }

  return badgeText;
};

export const _formatNumberWithCommas = (number) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const _formatSuggestions = (inputValue, itemField) => {
  const input = (inputValue || "").toLowerCase();
  const arr = itemField?.toLowerCase()?.split(input) || [];
  let result;
  result = arr.join(`<strong>${input}</strong>`);
  return result;
};

export const moneyFormater = (currency, number, opts) => {
  if (typeof number !== "number") return;
  if (opts?.divideBy100) number = number / 100;
  const formattedNumber = _formatNumberWithCommas(number);
  if (!currency) return formattedNumber;
  return `${getSymbolFromCurrency(currency)}${formattedNumber}`;
};

export const _isEmpty = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
      return false;
    }
  }
  return true;
};

export function removeNonNumeric(str = "") {
  if (!str) return 0;
  str = str.replace(/[^\d.-]/g, "");
  return parseInt(str, 10);
}

export const _everyObjectKeyHasValue = (obj) => {
  return Object.values(obj).every((o) => !!o);
};

export const _sortItems = (items, sortParameter, order = "up") => {
  return items.sort((a, b) => {
    const prev = typeof a[sortParameter] === "string" ? a[sortParameter].toLowerCase() : a[sortParameter];
    const next = typeof b[sortParameter] === "string" ? b[sortParameter].toLowerCase() : b[sortParameter];

    if (order === "up") {
      return prev < next ? -1 : prev > next ? 1 : 0;
    }
    return prev > next ? -1 : prev < next ? 1 : 0;
  });
};

export const convertFile = (file, type) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (type === "toCsv") {
      reader.readAsText(file);
      reader.onload = () => resolve(csv2json(reader.result));
    } else {
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    }
    reader.onerror = (error) => reject(error);
  });

export const _updateTheme = (primaryColour, secondaryColour) => {
  document.documentElement.style.setProperty("--primary-color", primaryColour);
  document.documentElement.style.setProperty("--secondary-color", secondaryColour);
};

export const _formatDate = (date, format) => {
  let _date = new Date(date);
  let month = "" + (_date.getMonth() + 1);
  let day = "" + _date.getDate();
  let year = _date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (format === "MM-DD-YY") {
    return [month, day, year].join("-");
  }

  if (format === "MM/DD/YY") {
    return [month, day, year].join("-");
  }

  if (format === "DD/MM/YY") {
    return [day, month, year].join("/");
  }

  if (format === "YY/MM/DD") {
    return [year, month, day].join("/");
  }

  if (format === "DD-MM-YY") {
    return [day, month, year].join("-");
  }

  if (format === "YY-MM-DD") {
    return [year, month, day].join("-");
  }

  return [year, month, day].join("-");
};

// eslint-disable-next-line no-unused-vars
export const _showTimeStamp = (timeStamp) => {
  const date = new Date(timeStamp);

  // Get the components of the date
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Convert hours to AM/PM format
  let ampm = hours >= 12 ? "PM" : "AM";
  let hour12 = hours % 12;
  hour12 = hour12 ? hour12 : 12; // Convert 0 to 12

  const time = `${hour12}:${minutes}:${seconds} ${ampm}`;

  return { date, time };
};

export const _verifyExisting = (input, arr, attr, option) => {
  if (arr !== undefined) {
    const newArr = arr.filter((item) => {
      const condition = item[attr]?.toLowerCase() === input?.toLowerCase();

      if (option?.ignorePrev) {
        return item[attr]?.toLowerCase() !== option?.ignorePrev?.toLowerCase() && condition;
      }
      return condition;
    });
    return !!newArr.length;
  }
};

export const getFormattedHours = (minDate) => {
  let format;
  let minutes = Math.floor(minDate % 60);
  let totalHours = Math.floor(minDate / 60);
  let days = Math.floor(totalHours / 24);
  let hours = Math.floor(totalHours % 24);
  if (days >= 1) {
    if (days === 1) {
      format = `${totalHours !== 0 ? `${totalHours} hours` : ""} ${minutes !== 0 ? `${minutes} minutes` : ""}`;
    } else {
      format = `${days} ${days > 1 ? "days" : "day"} ${hours !== 0 ? `${hours} hours` : ""} ${
        minutes !== 0 ? `${minutes} minutes` : ""
      }`;
    }
  } else if (days === 0) {
    format = ` ${hours} ${hours > 1 ? "hours" : "hour"} ${minutes !== 0 ? `${minutes} minutes` : ""}`;
  } else {
    format = `${minutes > 1 ? "minutes" : "minute"}`;
  }
  return format;
};

export const _handleNumericKeyPress = (e) => {
  if (e.keyCode > 47 && e.keyCode < 58) {
    e.preventDefault();
  }
};

export const toAmPm = (value) => {
  const [hours, minutes] = value.split(":");
  const AmOrPm = Number(hours) >= 12 ? "PM" : "AM";

  const newHours = Number(hours) % 12 || 12;

  return `${newHours}:${minutes}${AmOrPm}`;
};

export const convertTimeSlotTo12HrFormat = (time) => {
  if (!time) return "";
  const [time1, time2] = time.split(" - ");

  return `${toAmPm(time1)} - ${toAmPm(time2)}`;
};

export const _validateForm = (value, attr, obj, errorState, countryCode, allowSpecialChar) => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regexAlphaNumeric = /^[A-Z a-z/0-9]+$/;

  if (attr === "email" || attr === "nokEmail") {
    obj[attr] = value.toLowerCase();
    errorState = {
      ...errorState,
      [attr]: value.length && value.match(regexEmail) ? false : true,
    };
  } else if (attr === "phoneNumber" || attr === "nokPhoneNumber") {
    obj[attr] = value;
    const code = value.substr(0, 3);
    const number = value.substr(3);
    if (code === "234" && (number.length < 10 || number.length > 10)) {
      errorState = {
        ...errorState,
        [attr]: true,
        phoneNumber_error: "Phone number must be 10 digits",
      };
    } else if (code === "234" && number.length === 10) {
      errorState = {
        ...errorState,
        [attr]: false,
      };
    } else if (code !== "234" && number.length > 15) {
      errorState = {
        ...errorState,
        [attr]: true,
        phoneNumber_error: "Phone number must be less than 15 digits",
      };
    }
  } else if (attr === "name" || attr === "title") {
    obj[attr] = allowSpecialChar
      ? value.replace(/[0-9]/g, "")
      : value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, " ");
    errorState = {
      ...errorState,
      [attr]: value.length && regexAlphaNumeric.test(value) ? false : true,
    };
  } else {
    obj[attr] = value;
    errorState = {
      ...errorState,
      [attr]: !value,
    };
  }
  return { obj, errorState };
};

export const getSelectOptions = (base = 1, max = 10) => {
  const myKeys = [];
  do {
    myKeys.push({ label: base, value: base });
    base++;
  } while (base <= max);

  return myKeys;
};

export const _validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const _transformToSelectOptions = (data, label, title = "", isMulti = true, { includeProps = [] } = {}) => {
  if (isMulti) {
    return data?.map((datum) => {
      const obj = {};
      obj.label = datum[label];
      obj.value = String(datum._id);
      includeProps.forEach((e) => (obj[e] = datum[e]));
      if (title) obj.title = datum[title];
      return obj;
    });
  }
  const obj = {};
  obj.label = data[label];
  obj.value = String(data._id);
  return obj;
};

export const _transformStringToSelectOptions = (string) => {
  if (typeof string === "string" && string.trim() !== "") {
    return string?.split(",")?.map((value) => {
      const obj = {};
      obj.label = value;
      obj.value = value;
      return obj;
    });
  }
};

export const _undoTransformToSelectOptions = (options, label, title, isMulti = true, { includeProps = [] } = {}) => {
  if (isMulti) {
    return options.map((datum) => {
      if (label) {
        const obj = {};
        obj._id = datum.value;
        obj[label] = datum.label;
        includeProps.forEach((e) => (obj[e] = datum[e]));
        if (title) obj[title] = datum.value;
        return obj;
      }

      return datum[title];
    });
  }
  const obj = {};
  obj[label] = options.label;
  obj._id = options.value;
  return obj;
};

export const _reactivationToast = (msg, option) => {
  if (msg) {
    toast.success(msg, { hideProgressBar: true });
  } else {
    toast.error(`${option} could not be reactivated, please try again!`, {
      hideProgressBar: true,
    });
  }
};

export const _notifySuccess = (msg) => toast.success(msg, { hideProgressBar: true });

export const _notifyError = (msg) => toast.error(msg, { hideProgressBar: true, theme: "colored" });
export const _notifyWarning = (msg) => toast.warn(msg, { hideProgressBar: true });

export const _notifyCustom = ({ duration, component: Component, ...props }) => {
  const soundDetails = localStorage.getItem(`sound`);
  const savedSound = soundDetails !== "undefined" ? JSON.parse(soundDetails) : "";
  const tuneDetails = savedSound ? TUNES?.filter((tune) => tune?.name === savedSound) : TUNES[0];
  const audio = new Audio(tuneDetails[0]?.audio);

  if (!toast.isActive(props.toastId)) {
    toast(({ closeToast }) => <Component {...props} close={closeToast} />, {
      position: "top-right",
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: false,
      toastId: props.toastId,
      limit: props.limit,
      onOpen: async () => {
        if (tuneDetails?.name !== "None") {
          await audio.play();
        }
      },
      className: "custom-react-toastify",
    });
  }
};

export const _canProceed = (prev, current) => {
  const trackerOptions = [...setupOptions];
  const _lastSavedIndex = trackerOptions.findIndex((section) => section.to.includes(prev));

  const _currentlySavedIndex = trackerOptions.findIndex((section) => section.to.includes(current));
  return _currentlySavedIndex > _lastSavedIndex;
};

export const _ellipsizeText = (text, maxLength) => {
  if (text && String(text)) {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  }
  return text;
};

export const getCustomizationOptions = (existingSettings) => {
  const _personalDetails = PERSONAL_DETAILS.map((item) => {
    if (existingSettings && existingSettings[item.value]) {
      item = existingSettings[item.value];
    }
    return item;
  });
  const _nextOfKin = NEXT_OF_KIN.map((item) => {
    if (existingSettings && existingSettings[item.value]) {
      item = existingSettings[item.value];
    }
    return item;
  });
  const _hmoDetails = HMO_DETAILS.map((item) => {
    if (existingSettings && existingSettings[item.value]) {
      item = existingSettings[item.value];
    }
    return item;
  });
  const _EXTRA_DETAILS = EXTRA_DETAILS.slice(0, EXTRA_DETAILS.length);
  const _extraDetails = _EXTRA_DETAILS.map((item) => {
    if (existingSettings && existingSettings[item.value]) {
      item = existingSettings[item.value];
    }
    return item;
  });

  return {
    _personalDetails,
    _nextOfKin,
    _hmoDetails,
    _extraDetails,
  };
};

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const updatedState = (data) => ({
  isSaving: false,
  error: "",
  items: data,
  filteredItems: data,
});

export const getNextUrl = (nextSection) => {
  if (nextSection !== undefined) {
    return nextSection === false
      ? "/onboarding/completed"
      : `/onboarding/configuration?tab=${nextSection || "branches"}`;
  }
};

export const goBackUrl = (section) => {
  if (section !== undefined) {
    window.location = `/onboarding/configuration?tab=${section}`;
  } else {
    window.location = `/onboarding/`;
  }
};

export const getModule = () => {
  return window.location.pathname.split("/")[1];
};

export const isOnboarding = () => {
  const location = window.location.href;
  return location.includes("onboarding");
};

export const isOutPatient = () => {
  const location = window.location.href;
  return location.includes("out-patient");
};

export const isInPatient = () => {
  const location = window.location.href;
  return location.includes("in-patient");
};

export const isSetPassword = () => {
  const location = window.location.href;
  return location.includes("reset-password");
};

export const isPharmacy = () => {
  const location = window.location.href;
  return location.includes("pharmacy");
};

export const isBilling = () => {
  const location = window.location.href;
  return location.includes("billing");
};

export const isPharmacyDispensedDrugs = () => {
  const location = window.location.href;
  return location.includes("pharmacy") && location.includes("dispensed-drugs");
};

export const isPharmacyExpiredDrugs = () => {
  const location = window.location.href;
  return location.includes("pharmacy") && location.includes("expired-drugs");
};

export const isPharmacyStock = () => {
  const location = window.location.href;
  return location.includes("pharmacy") && location.includes("stock");
};

export const isDocket = () => {
  const location = window.location.href;
  return location.includes("med-docket");
};

export const isFrontdeskPatient = () => {
  const location = window.location.href;
  return location.includes("/frontdesk/patients");
};

export const isOutPatientProfile = () => {
  const location = window.location.href;
  return location.includes("/out-patient/patients");
};

export const isAccessLog = () => {
  const location = window.location.href;
  return location.includes("/access-log");
};

export const isPharmacyMedDocket = () => {
  const location = window.location.href;
  return location.includes("/pharmacy/sales?tab=med-docket");
};

export const isRetail = () => {
  const location = window.location.href;
  return location.includes("retail-items");
};

export const isPrescription = () => {
  const location = window.location.href;
  return location.includes("prescription");
};

export const isAdmitted = () => {
  const location = window.location.href;
  return location.includes("admitted-patient");
};

export const isSales = () => {
  const location = window.location.href;
  return location.includes("sales");
};

export const isOutorInPatient = () => {
  const location = window.location.href;
  return location.includes("out-patient") || location.includes("in-patient");
};

export const getBranchPermissions = (branches) => {
  let permissions = [];
  branches.forEach((branch) => {
    permissions = concat(permissions, branch.permissions);
  });
  return [...new Set(permissions)];
};

export const dateWithIntervals = (start, seperater) => {
  // this function should be called timeWithIntervals
  // eslint-disable-next-line
  let toAppend = [];
  try {
    if (start > 11 || seperater > 60) {
      throw Error;
    }
    // eslint-disable-next-line
    for (var i = start; i < 24; i++) {
      let hour;
      // eslint-disable-next-line
      i < 10 ? (hour = `0${i}`) : (hour = `${i}`);
      // eslint-disable-next-line
      for (var minute = 0; minute < 60; minute = minute + seperater) {
        if (minute < 60) {
          minute < 10
            ? toAppend.push(`${hour} : 0${minute} ${i < 12 ? "AM" : "PM"}`)
            : toAppend.push(`${hour} : ${minute} ${i < 12 ? "AM" : "PM"}`);
        }
      }
    }

    return toAppend;
  } catch (err) {
    return [`00 : 00`];
  }
};

export const getAppointmentTimes = (from = 8, to = 16) => {
  const appointmentTimes = [];

  for (var baseHour = from; baseHour <= to; baseHour++) {
    let hour_ = baseHour % 12;
    hour_ = hour_ === 0 ? 12 : hour_;
    const prefix = baseHour > 11 && baseHour < 24 ? "PM" : "AM";
    Array.from({ length: 60 }, (_, id) => (id < 10 ? `0${id}` : id)).forEach((sec) => {
      appointmentTimes.push({
        label: `${hour_}:${sec} ${prefix}`,
        value: `${hour_}:${sec} ${prefix}`,
      });
    });
  }

  return appointmentTimes;
};
export const generateArrayData = (fields) =>
  fields.map((field, index) => ({
    id: index + 1,
    type: field,
    typeValue: field.toLowerCase(),
    leftEye: "",
    rightEye: "",
  }));

export const accessDurationOptions = (interval, max = 60 * 24) => {
  // eslint-disable-next-line
  const myKeys = [];

  try {
    if (interval > 60 || max > 60 * 24) {
      throw Error;
    }
    // eslint-disable-next-line
    for (var baseMin = interval; baseMin <= max; baseMin = baseMin + interval) {
      // eslint-disable-next-line
      const remainder = baseMin % 60;
      const hour = (baseMin / 60).toString().split(".")[0];
      const hourString = Number(hour) === 0 ? "" : `${Number(hour) === 1 ? `${hour} hour` : `${hour} hours`}`;
      myKeys.push({
        label: `${hourString} ${Number(remainder) === 0 ? "" : `${remainder} Minutes`}`.trim(),
        value: baseMin,
      });
    }

    return myKeys;
  } catch (err) {
    return [{ label: "10 Minutes", value: "10 Minutes" }];
  }
};

export const fancyDate = (date) => {
  const date_ = new Date(date).toLocaleString().replace(/\//g, "-").split(",");
  const hour = new Date(date).getHours();
  let minute = String(new Date(date).getMinutes());
  minute = minute.length === 1 ? `0${minute}` : minute;

  // let hour_ = String(hour > 11 ? 12 - (24 - hour) : hour);
  let hour_ = String(((hour + 11) % 12) + 1);
  hour_ = hour_.length === 1 ? `0${hour_}` : hour_;

  const prefix = hour > 11 ? "pm" : "am";

  return `${date_[0]} ${hour_}:${minute} ${prefix}`;
};
export const fancyDateNoTime = (date) => {
  const date_ = new Date(date).toLocaleString().replace(/\//g, "-").split(",");

  return `${date_[0]}`;
};

export const checkDate = (date) => {
  let isToday = false;
  if (date) {
    const today = new Date().setHours(0, 0, 0, 0);
    const checkingDate = new Date(date).setHours(0, 0, 0, 0);
    isToday = today === checkingDate;
  }
  return isToday;
};

export const getPageTitleFromUrl = (location, index = 1) => {
  const locationParams = location.pathname.split("/");
  return locationParams[locationParams.length - index];
};

export const isStringEmpty = (value) => {
  if ([undefined, null, NaN].includes(value)) return true;
  return String(value).trim() === "";
};

export const isObjectEmpty = (obj) => {
  try {
    return Object.entries(obj).map(([key]) => key).length === 0;
  } catch (error) {
    return true;
  }
};

export const generateFilterParam = (obj) => {
  const query = {};
  if (!isObjectEmpty(obj)) {
    Object.entries(obj).map((item) => {
      if (!item[1]) {
        return item;
      }
      query[item[0]] = item[1];
      return item;
    });
  }
  return query;
};

export const calculateAge = (dob) => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const calculateActualAge = (dob) => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);
  let result;

  const ageInMonths = age_dt.getUTCMonth();
  const ageInDays = age_dt.getUTCDay();
  const ageInYears = Math.abs(age_dt.getUTCFullYear() - 1970);

  if (ageInYears > 0) {
    result = `${ageInYears} Year${ageInYears > 1 ? "s" : ""} Old`;
  } else if (ageInMonths > 0) {
    result = `${ageInMonths} Month${ageInMonths > 1 ? "s" : ""} Old`;
  } else {
    result = `${ageInDays} Day${ageInDays > 1 ? "s" : ""} Old`;
  }

  return result;
};

export const getPreviewTitles = (arr) => {
  let combinations = [];
  let max = arr.length - 1;

  if (arr && arr.length) {
    const helper = (arr, i) => {
      for (let j = 0, l = arr[i].length; j < l; j++) {
        let a = arr.slice(0);
        a.push(arr[i][j]);
        if (i === max) {
          combinations.push(a);
        } else helper(a, i + 1);
      }
    };
    helper(arr, 0);
    return combinations.map((comb) => {
      const updatedCombination = [...comb].filter((variant, index) => index >= arr.length);
      return updatedCombination.join("/");
    });
  }
};

export const cleanUpObject = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!["boolean", "number"].includes(typeof value) && !value) return { ...acc };
    if (typeof value === "object" && !Object.keys(value || {}).length) return { ...acc };
    return { ...acc, [key]: value };
  }, {});
};

const serializeValue = (key, value, indexed) => {
  if (Array.isArray(value)) {
    const valueString = value.join(",");
    if (indexed) {
      return value.map((el, idx) => `${key}[${idx}]=${el}`).join("&");
    }
    return `${key}=${valueString}`;
  }
  return `${key}=${value}`;
};

export const getSerializedParams = (obj, indexed = false) => {
  if (!obj) return undefined;
  return Object.entries(obj)
    .filter(([, value]) => value !== undefined && value !== null && value !== "")
    .map(([key, value]) => serializeValue(key, value, indexed))
    .join("&");
};

export const isReceived = (obj) => {
  if (obj?.status === "approved") {
    return "approved";
  }

  if ((obj && obj.received?.by) || obj?.status === "completed") {
    return "received";
  }

  return obj.status;
};

export const isSearchStringEmpty = (item) => {
  item = String(item).trim();
  return !isStringEmpty(item) ? item === "null" || item === "undefined" : true;
};

export const userSpecifics = (userObj) => {
  const currentUser = userObj.current || {};
  const parentOrg = (userObj.orgBranches || []).find((org) => org.value === currentUser.parentOrganizationId);
  const isOnBranch = currentUser.branchId !== undefined && currentUser.branchId !== parentOrg;
  const parent = (userObj.current || {}).parent === true;

  return { isOnBranch, parent };
};

export const populateUsersBranches = (allBranches) => {
  let branches = [{ label: "All", value: "all" }];
  branches = [
    ...branches,
    ...allBranches.map((branch) => ({
      label: branch.label,
      value: branch.value,
    })),
  ];
  return branches;
};

//sets the time for today to 00:00:00
export const setDateTo12 = (date = new Date()) => {
  return new Date(new Date(date).toDateString());
};

// https://stackoverflow.com/questions/1484506/random-color-generator
export const generateRandomColor = () => {
  return "#" + (((1 << 24) * Math.random()) | 0).toString(16);
};

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

export const randomId = guidGenerator;

const getDigitsFromValue = (value = "") => value.replace(/(-(?!\d))|[^0-9|-]/g, "") || "";

const padDigits = (digits) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = "0".repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, "$1");

function addDecimalToNumber(number, separator) {
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
  const cents = number.substring(centsStartingPosition);
  return dollars + separator + cents;
}

function numberWithCommas(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const toCurrency = (value, separator = ".") => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);
  const digitsWithDecimal = addDecimalToNumber(digitsWithPadding, separator);
  return numberWithCommas(digitsWithDecimal);
};

export const getPastYears = (number) => new Date(`${new Date().getFullYear() - number}`);

export const compressImage = async (image, options = {}) => {
  return await compressAccurately(image, {
    size: 70,
    scale: 0.5,
    ...options,
  });
};

export const extractHeaderAndData = (string) => {
  const header = string.split("\n")[0];
  const headerLength = header.length;
  const data = string.substr(headerLength);

  return [header.split(","), data];
};

export const capitalizeWords = (str = "") => {
  return str.replace(/(^\w)|(\s+\w)/g, (letter) => letter.toUpperCase());
};

export const convertCamelToString = (str) => {
  return str?.replace(/([A-Z])/g, " $1")?.replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export const removeWordSeparator = (word = "", separator = "-") => {
  if (typeof word !== "string") return word;
  const newStr = word.split(separator).join(" ");
  return capitalizeWords(newStr);
};

export const getFormattedDate = (date) => {
  if (date === new Date()) return;
  return _formatDate(date, "YY-MM-DD");
};

export const hasZeroReadings = (arr = []) => {
  return arr.every((dataset) => {
    return dataset.data.every((dataItem) => dataItem === 0);
  });
};

export const _transformGroupedSelectOptions = (options) => {
  if (options.length < 1) return [];
  const groups = [];
  const labels = [];
  options.forEach((option) => {
    if (labels.includes(option.group)) {
      return;
    }
    const groupedOptions = options.filter((opt) => opt.group === option.group);
    groups.push({ label: option.group, options: groupedOptions });
    labels.push(option.group);
  });
  return groups;
};

export const cutOutString = (str = "", start, end) => {
  return `${str.substring(0, str.indexOf(start))} ${str.substring(str.lastIndexOf(end) + 1)}`;
};

export function purgeFalsyAtt(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" || obj[key] === undefined || obj[key] === null || isObjectEmpty(obj[key])) {
      delete obj[key];
    }
  });
  return obj;
}

export const requiredRequestProcedureKeys = [
  "procedureType",
  "category",
  "test",
  "patient",
  "branch",
  "comment",
  "isUrgent",
  "procedureName",
  "hospital",
  "source",
  "specialty",
  "section",
];

export const requiredEditRequestProcedureKeys = [
  "test",
  "comment",
  "hospital",
  "source",
  "specialty",
  "section",
  "procedureType",
];

export const requiredProcedureKeys = [
  "attachments",
  "branch",
  "category",
  "checks",
  "procedureName",
  "duration",
  "test",
  "comment",
  "conclusion",
  "sourceId",
  "procedureId",
];

export const getRangeOfValues = (from = 0, to = 10) => {
  const nulled = [from, to].every((val) => !val);
  if (nulled) return [];
  return Array.from({ length: to + 1 - from }, (_, i) => i + from);
};

export const _formatAllergies = (allergies) => {
  if (Array.isArray(allergies)) {
    return allergies
      ?.map((allergy) => {
        if (typeof allergy === "object") return allergy?.name;
        return String(allergy);
      })
      .join(", ");
  }

  return String(allergies);
};

export const testStandardToSelectOptions = (items, name, specialty) => {
  const standard = items?.find(
    (standard) =>
      standard?.name?.toLowerCase() === name?.toLowerCase() && standard?.specialties?.includes(specialty.toLowerCase()),
  );
  if (standard) {
    return standard?.values.split(",").map((value) => ({ label: value, value }));
  }
  return [];
};

export const errorFormat = (error) => {
  return error?.response?.data?.message || error?.response?.data || CUSTOM_ERROR_MESSAGE;
};

export const patientTransferModules = {
  general: ["FRONTDESK", "VITALS/NURSES", "CONSULTANT", "PHARMACY", "LABORATORY", "IN-PATIENT", "BILLING"],
  ophthamology: [
    "FRONTDESK",
    "VITALS/NURSES",
    "CONSULTANT",
    "PHARMACY",
    "OPHTHALMOLOGIST",
    "IN-PATIENT",
    "OPTICAL MANAGER",
    "OPTOMETRIST",
    "RESIDENT DOCTOR",
    "BILLING",
    "LABORATORY",
  ],
  both: [
    "FRONTDESK",
    "VITALS/NURSES",
    "CONSULTANT",
    "PHARMACY",
    "LABORATORY",
    "OPHTHALMOLOGIST",
    "IN-PATIENT",
    "OPTICAL MANAGER",
    "OPTOMETRIST",
    "RESIDENT DOCTOR",
    "BILLING",
  ],
};

export const todayIsWithinDaysFrom = (date, noOfDays) => {
  const daysInMilliSec = 1000 * 60 * 60 * 24 * noOfDays;
  const timeInMilliAfterDate = new Date(date).getTime() + daysInMilliSec;
  return moment().isAfter(timeInMilliAfterDate);
};

export const getBranchServices = (services = [], branchId) => {
  const servicesArray = services.filter((service) => service?.branches?.some((branch) => branch._id === branchId));

  return servicesArray.map((service) => {
    return {
      ...service,
      price: service.branches?.[0]?.price || 0,
    };
  });
};

export const formatDiagnosis = (diagnosis) => {
  if (Array.isArray(diagnosis)) {
    return diagnosis;
  }
  return !Object.keys(diagnosis || {}).length ? [] : [diagnosis];
};

export const verifyConditions = (conditions) => conditions.every((condition) => condition);
export const getBranchStaffs = (staffs = [], branchId) => {
  return staffs.filter((staff) => staff?.locations?.some((location) => location._id === branchId));
};

export const extractProcedureData = (obj) => {
  if (!obj?._id) return obj;
  const { source, _id, ...rest } = obj;
  return { ...rest, sourceId: source?._id, procedureId: _id };
};

export const getPrefilledKeys = (obj = {}) => {
  if (obj === null) return [];
  return Object.keys(obj);
};

export const formatObjKeys = (data = []) => {
  return (data || []).map((singleData) => {
    const param = {};
    Object.entries(singleData).forEach(([key, value]) => {
      param[key.replace(/ /g, "_")] = value;
    });
    return generateFilterParam(param);
  });
};

export const TIMEOUTS = {
  IDLE_TIMEOUT: 1800 * 1000,
  LOGOUT_POPUP: 1800 * 1000,
  ONE_SEC: 1000,
};

export const getDifferenceInDays = (expiresAt) => {
  const a = moment(new Date());
  const b = moment(expiresAt);
  const difference = b.diff(a, "days");
  return difference < 0 ? 0 : difference;
};
export const getHmoDetails = (sellingPrice, { hmoPrice, percentage }) => {
  let percent = percentage;
  let price = hmoPrice;
  if (sellingPrice !== 0) {
    if (percentage !== 0) {
      price = (sellingPrice * percentage) / 100;
    }

    if (hmoPrice !== 0) {
      percent = (hmoPrice * 100) / sellingPrice;
    }
  }

  return { price, percent };
};

export const addOneDay = (date) => {
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
  const currentDate = new Date(date);
  const timestamp = currentDate.getTime();
  const tomorrow = new Date(timestamp + ONE_DAY_IN_MS);

  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
  const day = String(tomorrow.getDate()).padStart(2, "0");

  return [year, month, day].join("/");
};

export function subtractTimeFromDate(inputDate, amount, unit) {
  const date = new Date(inputDate);

  if (unit === "months") {
    date.setMonth(date.getMonth() - amount);
  } else if (unit === "days") {
    date.setDate(date.getDate() - amount);
  } else {
    throw new Error('Invalid unit. Please use "months" or "days".');
  }

  return date;
}

export function convertTo12HrFormat(timeStr) {
  const [hours, minutes] = timeStr.split(":").map(Number);

  const period = hours < 12 ? "AM" : "PM";

  let formattedHours = hours % 12;
  if (formattedHours === 0) {
    formattedHours = 12;
  }

  return `${formattedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${period}`;
}

export function categorizeSchedules(data) {
  const result = {
    morning: [],
    afternoon: [],
    evening: [],
  };

  for (const doctor of data) {
    const { consultant, schedules, branchId, specialty } = doctor;
    const scheduleData = { consultant, schedules, branchId, specialty };

    for (const day in schedules) {
      const dailySchedules = schedules[day];

      let morningStart = "";
      let morningEnd = "";
      let afternoonStart = "";
      let afternoonEnd = "";
      let eveningStart = "";
      let eveningEnd = "";

      for (const schedule of dailySchedules) {
        if (typeof schedule === "string") {
          const [startTime, endTime] = schedule.split(" - ");
          const hour = parseInt(startTime.split(":")[0], 10);

          if (hour >= 0 && hour < 12) {
            if (morningStart === "") {
              morningStart = startTime;
            }
            morningEnd = endTime;
          } else if (hour >= 12 && hour < 16) {
            if (afternoonStart === "") {
              afternoonStart = startTime;
            }
            afternoonEnd = endTime;
          } else {
            if (eveningStart === "") {
              eveningStart = startTime;
            }
            eveningEnd = endTime;
          }
        }
      }

      if (morningStart !== "") {
        result.morning.push({
          ...scheduleData,
          day,
          schedule: `${morningStart} - ${morningEnd}`,
        });
      }

      if (afternoonStart !== "") {
        result.afternoon.push({
          ...scheduleData,
          day,
          schedule: `${afternoonStart} - ${afternoonEnd}`,
        });
      }

      if (eveningStart !== "") {
        result.evening.push({ ...scheduleData, day, schedule: `${eveningStart} - ${eveningEnd}` });
      }
    }
  }

  return result;
}

export function pickKeys(obj, keys) {
  if (!obj || !Array.isArray(keys)) {
    return {};
  }

  return keys.reduce((result, key) => {
    if (typeof key === "string" && Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export function omitKeys(obj, keys) {
  if (!obj || !Array.isArray(keys)) {
    return { ...obj };
  }

  const result = { ...obj };

  keys.forEach((key) => {
    if (typeof key === "string" && Object.prototype.hasOwnProperty.call(obj, key)) {
      delete result[key];
    }
  });

  return result;
}

export function omitMapKeys(obj, keys) {
  if (!obj || !Array.isArray(keys)) {
    return { ...obj };
  }

  const result = { ...obj };

  for (const key in result) {
    if (Array.isArray(result[key])) {
      result[key].forEach((val, index) => {
        keys.forEach((x) => {
          if (typeof x === "string" && Object.prototype.hasOwnProperty.call(val, x)) {
            delete result[key][index][x];
          }
        });
      });
    } else {
      keys.forEach((key) => {
        if (typeof key === "string" && Object.prototype.hasOwnProperty.call(obj, key)) {
          delete result[key];
        }
      });
    }
  }

  return result;
}

export function setGlobalFontSize(new_size) {
  document.documentElement.style = `font-size: ${new_size}%`;
}

export const findPatient = async (inputString, selected, handleLoadingState) => {
  handleLoadingState(true);
  try {
    const {
      data: { data },
      status,
    } = await Axios.get(`/patients?${selected}=${inputString}&isActive=true`);
    if (status) {
      if (!data.docs.length) {
        let message = `${selected.toUpperCase()} DOES NOT EXIST, FILL THE FORM AS A NEW PATIENT`;
        _notifyError(message);
      }
      return data.docs;
    }
  } catch (error) {
    handleLoadingState(false);
  } finally {
    handleLoadingState(false);
  }
};

const transformData = (data, nameWithStock = false) => {
  return (data || []).map((doc) => {
    const branchDetails = doc?.branches[0];
    return {
      ...doc,
      ...(nameWithStock && {
        name: `${doc?.name} ${doc?.stockOnHand ? `[${doc?.stockOnHand} in Stock]` : "[Out of Stock]"}`,
      }),
      price: branchDetails?._id ? branchDetails?.price : 0,
    };
  });
};

export const searchInventoryItems = async (props) => {
  const { inputString, branchId, type = "product", setIsLoading, urlParams, setError, withStock } = props;

  try {
    setIsLoading(true);
    const params = { name: inputString, isActive: true, type, ...urlParams };
    const { data } = await Axios.get(`/locations/${branchId}/items`, { params });
    if (data?.data?.docs.length > 0) {
      setError?.(false);

      return transformData(data?.data?.docs, withStock);
    }
    setError?.(true);
  } catch (error) {
    return [];
  } finally {
    setIsLoading(false);
  }
};

export function generateInitials(userName) {
  if (!userName) return "";
  const words = userName.split(" ");
  // Extract the first letter of each word and join them
  return words.map((word) => word.charAt(0).toUpperCase()).join("");
}

export function formattedAmountToNumber(currencyString) {
  // Remove any currency symbols and commas
  const cleanNumberString = currencyString.replace(/[^\d.-]/g, "");

  // Convert the cleaned string to a number
  const numericValue = parseFloat(cleanNumberString);

  // Return the numeric value
  return numericValue || 0;
}

export const getTomorrowDate = () => {
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
  const today = Date.now();
  const tomorrow = new Date(today + ONE_DAY_IN_MS);

  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
  const day = String(tomorrow.getDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
};

export const getErrorMessage = (error) => {
  return error.response ? error.response.data.message || error.response.data : CUSTOM_ERROR_MESSAGE;
};

export function createNestedObject(prevState, path, value) {
  const keys = path.split(".");
  const lastKey = keys.pop();

  const newState = { ...prevState };

  keys.reduce((nestedObj, key) => {
    nestedObj[key] = nestedObj[key] || {};
    return nestedObj[key];
  }, newState.visual)[lastKey] = value;

  return newState;
}

export const checkIsMultiSelect = (type, keys = []) => keys.includes(type);

export const convertToArray = (arr, key) => {
  const stringArr = [];
  for (const obj of arr) {
    stringArr.push(obj[key]);
  }
  return stringArr;
};

export const convertToOphtSchema = (post) => {
  let seg = { ...post };

  for (const key in post) {
    if (typeof post[key] === "object") {
      if (post[key].leftEye && Array.isArray(post[key].leftEye) && post[key].leftEye !== undefined) {
        seg = {
          ...seg,
          [key]: post[key]?.leftEye.map((leftEyeItem, index) => {
            if (post[key].rightEye && Array.isArray(post[key].rightEye) && post[key].rightEye !== undefined) {
              return {
                leftEye: leftEyeItem,
                ...(post[key].rightEye[index] && { rightEye: post[key].rightEye[index] }),
              };
            }
            return {
              leftEye: leftEyeItem,
            };
          }),
        };
      } else {
        delete seg[key].leftEye;
      }
      if (post[key].rightEye && Array.isArray(post[key].rightEye) && post[key].rightEye !== undefined) {
        seg = {
          ...seg,
          [key]: post[key]?.rightEye.map((rightEyeItem, index) => {
            if (post[key].leftEye && Array.isArray(post[key].leftEye) && post[key].leftEye !== undefined) {
              return {
                rightEye: rightEyeItem,
                ...(post[key].leftEye[index] && { leftEye: post[key].leftEye[index] }),
              };
            }
            return {
              rightEye: rightEyeItem,
            };
          }),
        };
      } else {
        delete seg[key].rightEye;
      }

      if (isObjectEmpty(seg[key])) delete seg[key];
    }
  }

  return seg;
};

export const convertToPosteriorObjectArray = (obj) => {
  let seg = { ...obj };

  for (const key in obj) {
    if (typeof obj[key] === "object") {
      if (obj[key].leftEye && Array.isArray(obj[key].leftEye)) {
        seg = {
          ...seg,
          [key]: {
            ...seg[key],
            leftEye: convertToArray(obj[key]["leftEye"], "label"),
          },
        };
      } else {
        seg = {
          ...seg,
          [key]: {
            ...seg[key],
          },
        };
      }
      if (obj[key].rightEye && Array.isArray(obj[key].rightEye)) {
        seg = {
          ...seg,
          [key]: {
            ...seg[key],
            rightEye: convertToArray(obj[key]["rightEye"], "label"),
          },
        };
      } else {
        seg = {
          ...seg,
          [key]: {
            ...seg[key],
          },
        };
      }
    }
  }

  const finalRes = convertToOphtSchema(seg);

  return finalRes;
};

export function formatPhoneNumber(phoneNumber) {
  // Check if the phone number starts with '+', if not, return it as is
  if (!phoneNumber.startsWith("+")) {
    return phoneNumber;
  }

  // Extract the country code and the rest of the number
  const countryCode = phoneNumber.substring(0, 4);
  const restOfNumber = phoneNumber.substring(4);

  // Format the rest of the number
  const part1 = restOfNumber.substring(0, 4); // First 4 digits
  const part2 = restOfNumber.substring(4, 7); // Next 3 digits
  const part3 = restOfNumber.substring(7); // Remaining digits

  // Combine the parts with spaces
  return `${countryCode} ${part1} ${part2} ${part3}`;
}

export function separateProducts(data) {
  return data.reduce((result, entry) => {
    const arrayKeys = Object.keys(entry).filter((key) => Array.isArray(entry[key]));

    entry.Items.forEach((product, index) => {
      const newEntry = { ...entry };

      arrayKeys.forEach((key) => {
        newEntry[key] = [entry[key][index]];
      });

      result.push(newEntry);
    });

    return result;
  }, []);
}

export function reverseTransform(transformedColumns) {
  const columns =
    transformedColumns.map((column) => ({
      row: column.layout.row,
      id: column._id, // Generate ID (since original IDs are not in the transformed data)
      type: column.type,
      label: column.label,
      placeholder: column.placeholder,
      required: column.required,
      ...(column.class && { class: column.class }),
      ...(column.multiple && { multiple: column.multiple }),
      ...(column.options && { options: column.options.map((option) => option.label).join(", ") }),
      ...(column.datasource && { datasource: column.datasource }),
      ...(column.table && {
        table: {
          ...column.table,
        },
      }),
    })) ?? [];

  const rows = transformedColumns.map((column) => column.layout.row) ?? [];
  return { columns, rows };
}

export function createQueryString(params, searchParams) {
  const newSearchParams = new URLSearchParams(searchParams?.toString());

  for (const [key, value] of Object.entries(params)) {
    if (value === null || value === undefined) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, String(value));
    }
  }

  return newSearchParams.toString();
}

// Function to extract the UTC offset from the string
function getUTCOffset(timeZoneString) {
  const match = timeZoneString.match(/\(UTC ([+-]\d{2}:\d{2})\)/);
  return match ? match[1] : null;
}

// Function to convert UTC times to local time
export const convertTimeslotsToLocal = (timeslots, timeZone) => {
  const utcOffset = getUTCOffset(timeZone);

  if (!utcOffset) {
    return [];
  }

  return timeslots.map((slot) => {
    // Split the timeslot into start and end times
    const [startTime, endTime] = slot.split(" - ");

    // Convert each time from UTC to the user's local timezone
    const localStartTime = moment_time.utc(startTime, "HH:mm").utcOffset(utcOffset).format("h:mm");
    const localEndTime = moment_time.utc(endTime, "HH:mm").utcOffset(utcOffset).format("h:mm");
    // Return the converted timeslot
    return { label: `${localStartTime} - ${localEndTime}`, value: slot };
  });
};

export const useFormValues = () => {
  const { getValues } = useFormContext();
  return {
    ...useWatch(), // subscribe to form value updates
    ...getValues(), // always merge with latest form values
  };
};

/**
 * Function to calculate the number of SMS pages required for a given text.
 * @param {string} text - The SMS text to analyze.
 * @returns {number} - The number of SMS pages required.
 */
export const calculateSMSPages = (text) => {
  // Special characters affecting the SMS character count
  const specialCharacters = [";", "/", "^", "{", "}", "\\", "[", "~", "]", "|", "€", "'", "”", "`"];

  // Check if the text contains any special character
  const containsSpecialCharacter = specialCharacters.some((char) => text.includes(char));

  // Define character limits based on the presence of special characters
  const charsPerPage = containsSpecialCharacter ? 70 : 160;

  // Calculate the number of pages required
  const pageCount = Math.ceil(text.length / charsPerPage);

  return pageCount;
};

export function areAllValuesEmpty(obj) {
  return Object.values(obj).every((value) => Array.isArray(value) && value.length === 0);
}
